<template>
  <div>
    <div class="content-wrapper">
      <nav aria-label="breadcrumb">
        <ol class="breadcrumb">
          <li class="breadcrumb-item">
            <a href="#">
              <i class="fa fa-home"></i>
              Dashboard
            </a>
          </li>
          <li class="breadcrumb-item active">Templates</li>
          <li class="breadcrumb-right">
            <button
              type="button"
              class="btn btn-success"
              data-toggle="modal"
              data-target="#exampleModal"
            >
              <i class="fas fa-plus"></i>
              Add Template
            </button>
          </li>
        </ol>
      </nav>
      <section class="content">
        <div class="container-fluid">
          <div class="row justify-content-md-center">
            <div class="col-sm-12">
              <div class="card">
                <div class="card-header">
                  <h3 class="card-title">Templates</h3>
                </div>
                <div class="card-body">
                  <div class="row">
                    <div class="col-sm-6">
                      <div class="form-group">
                        <input
                          class="form-control form-control-sm"
                          type="text"
                          placeholder="Search"
                          v-model="meta.search"
                          @keyup="search"
                          v-if="!meta.trashed"
                        />
                      </div>
                    </div>
                    <div class="col-sm-6">
                      <div class="form-group">
                        <select
                          class="form-control form-control-sm"
                          v-model="meta.status"
                          @change="search"
                        >
                          <option value="">Select Status</option>
                          <option
                            v-for="(status, key) in whatsapp_filters"
                            :key="key"
                            :value="status"
                          >
                            {{ status }}
                          </option>
                        </select>
                      </div>
                    </div>
                  </div>

                  <table
                    class="table table-responsive-sm table-bordered table-striped table-sm"
                  >
                    <thead>
                      <tr>
                        <th class="text-center">#</th>
                        <th @click="sort('template_name')">
                          Template Name
                          <span>
                            <i
                              v-if="
                                meta.keyword == 'template_name' &&
                                meta.order_by == 'asc'
                              "
                              class="fas fa-sort-up"
                            ></i>
                            <i
                              v-else-if="
                                meta.keyword == 'template_name' &&
                                meta.order_by == 'desc'
                              "
                              class="fas fa-sort-down"
                            ></i>
                            <i v-else class="fas fa-sort"></i>
                          </span>
                        </th>
                        <th @click="sort('template_type')">
                          Template Type
                          <span>
                            <i
                              v-if="
                                meta.keyword == 'template_type' &&
                                meta.order_by == 'asc'
                              "
                              class="fas fa-sort-up"
                            ></i>
                            <i
                              v-else-if="
                                meta.keyword == 'template_type' &&
                                meta.order_by == 'desc'
                              "
                              class="fas fa-sort-down"
                            ></i>
                            <i v-else class="fas fa-sort"></i>
                          </span>
                        </th>
                        <th @click="sort('header')">
                          Header
                          <span>
                            <i
                              v-if="
                                meta.keyword == 'header' &&
                                meta.order_by == 'asc'
                              "
                              class="fas fa-sort-up"
                            ></i>
                            <i
                              v-else-if="
                                meta.keyword == 'header' &&
                                meta.order_by == 'desc'
                              "
                              class="fas fa-sort-down"
                            ></i>
                            <i v-else class="fas fa-sort"></i>
                          </span>
                        </th>
                        <th @click="sort('message')">
                          Message
                          <span>
                            <i
                              v-if="
                                meta.keyword == 'message' &&
                                meta.order_by == 'asc'
                              "
                              class="fas fa-sort-up"
                            ></i>
                            <i
                              v-else-if="
                                meta.keyword == 'message' &&
                                meta.order_by == 'desc'
                              "
                              class="fas fa-sort-down"
                            ></i>
                            <i v-else class="fas fa-sort"></i>
                          </span>
                        </th>
                        <th @click="sort('footer')">
                          Footer
                          <span>
                            <i
                              v-if="
                                meta.keyword == 'footer' &&
                                meta.order_by == 'asc'
                              "
                              class="fas fa-sort-up"
                            ></i>
                            <i
                              v-else-if="
                                meta.keyword == 'footer' &&
                                meta.order_by == 'desc'
                              "
                              class="fas fa-sort-down"
                            ></i>
                            <i v-else class="fas fa-sort"></i>
                          </span>
                        </th>
                        <th @click="sort('category')">
                          Category
                          <span>
                            <i
                              v-if="
                                meta.keyword == 'category' &&
                                meta.order_by == 'asc'
                              "
                              class="fas fa-sort-up"
                            ></i>
                            <i
                              v-else-if="
                                meta.keyword == 'category' &&
                                meta.order_by == 'desc'
                              "
                              class="fas fa-sort-down"
                            ></i>
                            <i v-else class="fas fa-sort"></i>
                          </span>
                        </th>
                        <th @click="sort('media')">
                          Media
                          <span>
                            <i
                              v-if="
                                meta.keyword == 'media' &&
                                meta.order_by == 'asc'
                              "
                              class="fas fa-sort-up"
                            ></i>
                            <i
                              v-else-if="
                                meta.keyword == 'media' &&
                                meta.order_by == 'desc'
                              "
                              class="fas fa-sort-down"
                            ></i>
                            <i v-else class="fas fa-sort"></i>
                          </span>
                        </th>
                        <th @click="sort('status')">
                          Status
                          <span>
                            <i
                              v-if="
                                meta.keyword == 'status' &&
                                meta.order_by == 'asc'
                              "
                              class="fas fa-sort-up"
                            ></i>
                            <i
                              v-else-if="
                                meta.keyword == 'status' &&
                                meta.order_by == 'desc'
                              "
                              class="fas fa-sort-down"
                            ></i>
                            <i v-else class="fas fa-sort"></i>
                          </span>
                        </th>
                        <th class="text-center">Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr
                        v-for="(template, key) in whatsapp_templates"
                        :key="key"
                      >
                        <td class="text-center">{{ meta.from + key }}</td>
                        <td>{{ template.template_name }}</td>
                        <td>{{ template.template_type }}</td>
                        <td v-if="template.template_type == 'File'">-</td>
                        <td v-else>{{ template.header }}</td>
                        <td>{{ template.message }}</td>
                        <td>{{ template.footer }}</td>
                        <td>{{ template.category }}</td>

                        <td v-if="template.template_type == 'File'">
                          <a target="_blank" :href="template.media_link">
                            {{ template.media }}
                            <!-- <img width="50" :src="template.media" /> -->
                          </a>
                        </td>
                        <td v-else>-</td>
                        <td>{{ template.status }}</td>

                        <td class="text-center">
                          <button
                            v-if="template.status == 'APPROVED'"
                            class="btn btn-xs btn-outline-primary"
                            data-placement="bottom"
                            title="Send Message"
                            data-toggle="modal"
                            data-target="#uploadTemplate"
                            @click="uploadTemplate(template)"
                          >
                            <i class="fas fa-upload"></i>
                          </button>
                          <button
                            v-if="template.status == 'PENDING'"
                            class="btn btn-xs btn-outline-danger"
                            data-toggle="tooltip"
                            data-placement="bottom"
                            title="Pending"
                            @click="checkStatus(template)"
                            data-bs-toggle="modal"
                            data-bs-target="#checkStatus"
                          >
                            <!-- <i class="fa fa-spinner fa-spin" aria-hidden="true"></i> -->
                            <i
                              class="text-danger fas fa-spinner fa-spin pending-icon"
                              aria-hidden="true"
                            ></i>
                          </button>
                        </td>
                      </tr>
                      <tr v-if="whatsapp_templates.length <= 0">
                        <td colspan="20" style="text-align: center">
                          No Records Found
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div class="card-footer">
                  <div class="float-right">
                    <Pagination
                      :maxPage="meta.maxPage"
                      :totalPages="meta.lastPage"
                      :currentPage="meta.page"
                      @pagechanged="onPageChange"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>

    <!-- add template -->
    <div class="modal fade" id="exampleModal" ref="exampleModal">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLabel">Add Template</h5>
            <button
              type="button"
              id="close"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
              @click="discard_template"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <div class="row">
              <div class="col-sm-12">
                <div class="form-group">
                  <label for="transaction_date_time"
                    >Template Name <span class="text-danger">*</span></label
                  >
                  <input
                    type="text"
                    class="form-control form-control-sm"
                    placeholder="Template name"
                    v-model="template.template_name"
                    @input="formatTemplateName"
                    :class="{ 'is-invalid': errors.template_name }"
                  />
                  <span v-if="errors.template_name" class="invalid-feedback">{{
                    errors.template_name[0]
                  }}</span>
                </div>
              </div>
              <div class="col-sm-12">
                <div class="form-group">
                  <label for="transaction_date_time"
                    >Template Type <span class="text-danger">*</span></label
                  >
                  <select
                    class="form-control form-control-sm"
                    v-model="template.template_type"
                    :class="{ 'is-invalid': errors.template_type }"
                  >
                    <option value="">Select Template Type</option>
                    <option value="Text">Text</option>
                    <option value="File">File</option>
                  </select>
                  <span v-if="errors.template_type" class="invalid-feedback">{{
                    errors.template_type[0]
                  }}</span>
                </div>
              </div>
              <div class="col-sm-12" v-if="template.template_type == 'Text'">
                <div class="form-group">
                  <label for="transaction_date_time"
                    >Header <span class="text-danger">*</span></label
                  >
                  <input
                    type="text"
                    class="form-control form-control-sm"
                    placeholder="Header"
                    v-model="template.header"
                    :class="{ 'is-invalid': errors.header }"
                  />
                  <span v-if="errors.header" class="invalid-feedback">{{
                    errors.header[0]
                  }}</span>
                </div>
              </div>
              <div class="col-sm-12">
                <div class="form-group">
                  <label for="transaction_date_time"
                    >Category <span class="text-danger">*</span></label
                  >
                  <select
                    class="form-control form-control-sm"
                    v-model="template.category"
                    :class="{ 'is-invalid': errors.category }"
                  >
                    <option value="">Select Category</option>
                    <option value="Utility">Utility</option>
                    <option value="Marketing">Marketing</option>
                    <option value="Authentication">Authentication</option>
                  </select>
                  <span v-if="errors.category" class="invalid-feedback">{{
                    errors.category[0]
                  }}</span>
                </div>
              </div>

              <div class="col-sm-12">
                <div class="form-group">
                  <label for="transaction_date_time"
                    >Message <span class="text-danger">*</span></label
                  >
                  <!-- <textarea class="form-control form-control-sm" placeholder="Message"
                                    v-model="template.message" :class="{ 'is-invalid': errors.message }"></textarea> -->
                  <ckeditor
                    :editor="editor"
                    v-model="template.message"
                    :class="{ 'is-invalid': errors.message }"
                  ></ckeditor>
                  <span v-if="errors.message" class="invalid-feedback">{{
                    errors.message[0]
                  }}</span>
                </div>
              </div>
              <div class="col-sm-12" v-if="template.template_type == 'File'">
                <div class="form-group">
                  <label for="transaction_date_time"
                    >File <span class="text-danger">*</span></label
                  >
                  <input
                    type="file"
                    class="form-control form-control-sm"
                    placeholder="File"
                    @change="onChange($event)"
                    ref="file"
                  />
                  <span class="invalid-feedback"></span>
                </div>
              </div>

              <div class="col-sm-12" v-if="template.template_type == 'File'">
                <div class="form-group">
                  <label for="transaction_date_time"
                    >Variables <span class="text-danger">*</span></label
                  >
                  <input
                    type="text"
                    class="form-control form-control-sm"
                    placeholder="Variables"
                    v-model="template.variables"
                    :class="{ 'is-invalid': errors.variables }"
                  />
                  <span v-if="errors.variables" class="invalid-feedback">{{
                    errors.variables[0]
                  }}</span>
                </div>
              </div>

              <div class="col-sm-12">
                <div class="form-group">
                  <label for="transaction_date_time">Footer</label>
                  <input
                    type="text"
                    class="form-control form-control-sm"
                    placeholder="Footer"
                    v-model="template.footer"
                  />
                  <span class="invalid-feedback"></span>
                </div>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-secondary"
              data-dismiss="modal"
              @click="discard_template"
            >
              Close
            </button>
            <button
              type="button"
              class="btn btn-primary"
              @click="addTemplate()"
            >
              Save Changes
            </button>
          </div>
        </div>
      </div>
    </div>
    <!-- upload -->
    <div class="modal fade" id="uploadTemplate" ref="uploadTemplate">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLabel">Groups</h5>
            <button
              type="button"
              id="close1"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
              @click="discard_upload_template"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <div class="row">
              <div class="col-sm-12">
                <label>Send To : </label>
              </div>
              <div class="col-sm-6">
                <div class="form-check">
                  <input
                    class="form-check-input"
                    type="checkbox"
                    id="customer"
                    value="customer"
                    v-model="upload_template.customer"
                  />
                  <label class="form-check-label" for="customer">
                    Customer
                  </label>
                </div>
              </div>
              <div class="col-sm-6">
                <div class="form-check">
                  <input
                    class="form-check-input"
                    type="checkbox"
                    id="database"
                    value="database"
                    v-model="upload_template.database"
                  />
                  <label class="form-check-label" for="database">
                    Database
                  </label>
                </div>
              </div>
              <div class="col-sm-12" v-if="errors.to">
                <span style="color: red">{{ errors.to[0] }}</span>
              </div>
              <div class="col-sm-12 mt-3">
                <label>Groups : </label>
              </div>
              <div class="col-sm-12">
                <div class="form-check">
                  <input
                    class="form-check-input"
                    type="checkbox"
                    id="all"
                    @click="selectAll"
                    :checked="isAllSelected"
                  />
                  <label class="form-check-label" for="all"> Select All </label>
                </div>
              </div>
              <div class="col-sm-12" v-for="(group, key) in groups" :key="key">
                <div class="form-check">
                  <input
                    class="form-check-input"
                    type="checkbox"
                    :id="group.group_id"
                    :value="group.group_id"
                    v-model="grps"
                  />
                  <label class="form-check-label" :for="group.group_id">
                    {{ group.group }}
                  </label>
                </div>
              </div>
              <div class="col-sm-12" v-if="errors.groups">
                <span style="color: red">{{ errors.groups[0] }}</span>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-secondary"
              data-dismiss="modal"
              @click="discard_upload_template"
            >
              Close
            </button>
            <button
              type="button"
              class="btn btn-primary"
              @click="uploadMessage()"
            >
              Send Message
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Pagination from "@/components/Pagination.vue";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
export default {
  name: "Item.Index",
  components: { Pagination },
  data() {
    return {
      editor: ClassicEditor,
      template: {
        template_name: "",
        template_type: "",
        header: "",
        message: "",
        footer: "",
        category: "",
        mobile_no: [],
        file: {},
        media: "",
        variables: "",
      },
      status: true,
      meta: {
        branch_id: this.$store.getters.user.branch_id,
        search: "",
        order_by: "asc",
        keyword: "template_name",
        per_page: 10,
        totalRows: 0,
        page: 1,
        lastPage: 1,
        from: 1,
        maxPage: 1,
        trashed: false,
        status: "",
      },
      message: {
        mobileNumber: "",
        message: "",
      },
      upload_template: {
        template_name: "",
        // to: [],
        customer: false,
        database: false,
        groups: [],
      },
      grps: [],
      groups: [],
      items: [],
      errors: [],
      whatsapp_templates: [],
      whatsapp_filters: [],
    };
  },
  computed: {
    // Dynamically check if all groups are selected
    isAllSelected() {
      return this.grps.length === this.groups.length;
    },
  },
  mounted() {
    this.index();
    this.getStatus();
  },

  methods: {
    edit(item) {
      this.$router.push("/items/" + item.item_id + "/edit");
    },

    search() {
      let vm = this;
      vm.meta.page = 1;
      vm.index();
    },
    //without media
    // addTemplate() {
    //   let vm = this;
    //   let loader = vm.$loading.show();
    //   vm.$store
    //     .dispatch("post", { uri: "addTemplate", data: vm.template })
    //     .then(function () {
    //       loader.hide();
    //       vm.$store.dispatch("success", "Template is successfully created");
    //       let modal = vm.$refs.exampleModal;
    //       modal.style.display = "none";
    //       document.getElementById("close").click();
    //       vm.template.template_name = "";
    //       vm.template.header = "";
    //       vm.template.message = "";
    //       vm.template.footer = "";
    //       vm.template.category = "";
    //       vm.errors = [];
    //       vm.index();
    //     })
    //     .catch(function (error) {
    //       loader.hide();
    //       vm.errors = JSON.parse(error.response.data.error).message;
    //       vm.$store.dispatch(
    //         "error",
    //         JSON.parse(error.response.data.error).message
    //       );
    //     });
    // },

    //with media
    addTemplate() {
      let vm = this;
      let data = new FormData();
      data.append("template_name", vm.template.template_name);
      data.append("template_type", vm.template.template_type);
      data.append("header", vm.template.header);
      data.append("message", vm.template.message);
      data.append("footer", vm.template.footer);
      data.append("category", vm.template.category);
      data.append("mobile_no", vm.template.mobile_no);
      data.append("variables", vm.template.variables);
      data.append("file", vm.template.file);

      let loader = vm.$loading.show();
      vm.$store
        .dispatch("post", { uri: "addTemplate", data: data })
        .then(function () {
          loader.hide();
          vm.$store.dispatch("success", "Template is successfully created");
          let modal = vm.$refs.exampleModal;
          modal.style.display = "none";
          document.getElementById("close").click();
          vm.template.template_name = "";
          vm.template.template_type = "";
          vm.template.file = "";
          vm.template.header = "";
          vm.template.message = "";
          vm.template.footer = "";
          vm.template.category = "";
          vm.errors = [];
          vm.index();
        })
        .catch(function (error) {
          loader.hide();
          vm.errors = JSON.parse(error.response.data.error).message;
          vm.$store.dispatch(
            "error",
            JSON.parse(error.response.data.error).message
          );
        });
    },

    index() {
      let vm = this;
      vm.$store
        .dispatch("post", { uri: "getWhatsappTemplates", data: vm.meta })
        .then(function (response) {
          vm.whatsapp_templates = response.data.data;
          vm.meta.totalRows = response.data.meta.total;
          vm.meta.lastPage = response.data.meta.last_page;
          vm.meta.from = response.data.meta.from;
          vm.meta.maxPage = vm.meta.lastPage >= 3 ? 3 : vm.meta.lastPage;
          vm.getGroups();
        })
        .catch(function (error) {
          vm.errors = error.response.data.errors;
          vm.$store.dispatch("error", error.response.data.message);
        });
    },
    getGroups() {
      let vm = this;
      vm.$store
        .dispatch("post", { uri: "getGroups" })
        .then(function (response) {
          vm.groups = response.data.data;
        })
        .catch(function (error) {
          vm.errors = error.response.data.errors;
          vm.$store.dispatch("error", error.response.data.message);
        });
    },

    isTrashed() {
      let vm = this;
      vm.meta.trashed = !vm.meta.trashed;
      if (vm.meta.trashed) vm.meta.page = 1;
      vm.index();
    },

    deleteItem(item) {
      if (confirm("Do you want to continue... ?")) {
        let vm = this;
        let loader = vm.$loading.show();
        let uri = { uri: "deleteItem", data: item };
        vm.$store
          .dispatch("post", uri)
          .then(function () {
            loader.hide();
            vm.$store.dispatch("success", "Item is successfully deleted");
            vm.index();
            vm.trashed();
          })
          .catch(function (error) {
            loader.hide();
            vm.errors = error.response.data.errors;
            vm.$store.dispatch("error", error.response.data.message);
          });
      }
    },
    uploadTemplate(template) {
      let vm = this;
      vm.upload_template.template_name = template.template_name;
    },
    uploadMessage() {
      let vm = this;
      let loader = vm.$loading.show();
      vm.upload_template.groups = vm.grps;
      let uri = { uri: "sendWhatsappBroadcast", data: vm.upload_template };
      vm.$store
        .dispatch("post", uri)
        .then(function () {
          loader.hide();
          vm.$store.dispatch("success", "Message is successfully sent");
          // vm.$refs.Close.click();
          let modal = vm.$refs.uploadTemplate;
          modal.style.display = "none";
          document.getElementById("close1").click();
          vm.upload_template.template_name = "";
          vm.upload_template.customer = false;
          vm.upload_template.database = false;
          vm.upload_template.groups = [];
          vm.grps = [];
          vm.errors = [];
        })
        .catch(function (error) {
          loader.hide();
          vm.errors = error.response.data.errors;
          vm.$store.dispatch("error", error.response.data.message);
        });
    },
    checkStatus(template) {
      let data = {
        template_name: template.template_name,
        template_no: template.template_no,
      };
      let vm = this;
      let loader = vm.$loading.show();
      let uri = { uri: "checkTemplateStatus", data: data };
      vm.$store
        .dispatch("post", uri)
        .then(function () {
          loader.hide();
          vm.$store.dispatch("success", "Status is successfully updated");
          vm.index();
        })
        .catch(function (error) {
          console.log("EErr--", error?.response);
          loader.hide();
          vm.errors = error.response.data.errors;
          vm.$store.dispatch("error", error.response.data.errors);
        });
    },

    onChange(e) {
      let vm = this;
      vm.template.file = e.target.files[0];
    },

    onPageChange(page) {
      this.meta.page = page;
      this.index();
    },
    formatTemplateName() {
      this.template.template_name = this.template.template_name
        .toLowerCase()
        .replace(/\s+/g, "_"); // Replaces spaces with underscores
    },
    selectAll() {
      if (this.isAllSelected) {
        this.grps = []; // Clear selection if already all selected
      } else {
        this.grps = this.groups.map((group) => group.group_id); // Select all group IDs
      }
    },
    getStatus() {
      let vm = this;
      vm.$store
        .dispatch("post", { uri: "whatsappFilters" })
        .then(function (response) {
          console.log("respo---", response.data);
          vm.whatsapp_filters = response.data;
        })
        .catch(function (error) {
          vm.errors = error.response.data;
          vm.$store.dispatch("error", error.response.data.message);
        });
    },
    discard_template() {
      let vm = this;
      vm.template.template_name = "";
      vm.template.header = "";
      vm.template.message = "";
      vm.template.footer = "";
      vm.template.category = "";
      vm.errors = [];
    },
    discard_upload_template() {
      let vm = this;
      vm.upload_template.template_name = "";
      vm.upload_template.customer = false;
      vm.upload_template.database = false;
      vm.upload_template.groups = [];
      vm.grps = [];
      vm.errors = [];
    },
    sort(field) {
      this.meta.keyword = field;
      this.meta.order_by = this.meta.order_by == "asc" ? "desc" : "asc";
      this.index();
    },
  },
};
</script>

<style scoped>
.btn-outline-danger:hover {
  background: transparent !important;
  color: #dc3545;
  border-color: #dc3545;
}
</style>
