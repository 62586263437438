<template>
  <div class="content-wrapper">
    <nav aria-label="breadcrumb">
      <ol class="breadcrumb">
        <li class="breadcrumb-item">
          <a href="#">
            <i class="fa fa-home"></i>
            Dashboard
          </a>
        </li>
        <li class="breadcrumb-item">Session</li>
        <li class="breadcrumb-right">
          <router-link to="/sessions/history" class="btn btn-sm btn-success">
            <i class="fa fa-list"></i>
            Sessions
          </router-link>
        </li>
      </ol>
    </nav>

    <section class="content">
      <div class="container-fluid">
        <div class="row justify-content-md-center">
          <div class="col-sm-4">
            <div class="card">
              <div class="card-header">
                <h3 class="card-title">Session</h3>
              </div>
              <div class="card-body">
                <div class="table-responsive text-nowrap">
                  <table class="table table-striped table-bordered table-sm">
                    <tbody>
                      <tr>
                        <td>Session Date</td>
                        <td>{{ session.session_date }}</td>
                      </tr>
                      <tr>
                        <td>Session Time</td>
                        <td>{{ session.session_time }}</td>
                      </tr>
                      <tr>
                        <td>Session Type</td>
                        <td>{{ session.session_type }}</td>
                      </tr>

                      <tr v-if="session.user">
                        <td>User</td>
                        <td>{{ session.user.name }}</td>
                      </tr>
                      <tr v-if="session.session_type == 'Experience Fees'">
                        <td>Table</td>
                        <td>{{ session?.table?.table }}</td>
                      </tr>
                      <tr v-if="session.session_type == 'Tips'">
                        <td>Employee</td>
                        <td>{{ session?.employee?.name }}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
          <div class="col-sm-8">
            <div class="card">
              <div class="card-header">
                <h3 class="card-title">Denominations</h3>
              </div>
              <div class="card-body">
                <div class="table-responsive text-nowrap">
                  <table class="table table-bordered table-striped table-sm">
                    <thead>
                      <tr>
                        <th class="text-center">Denominations</th>
                        <th class="text-center">Quantity</th>
                        <th
                          class="text-center"
                          v-if="session.session_type == 'Exchange Chips'"
                        >
                          To Denominations
                        </th>
                        <th
                          class="text-center"
                          v-if="session.session_type == 'Exchange Chips'"
                        >
                          To Quantity
                        </th>
                        <th class="text-center">Total</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr
                        v-for="(
                          session_denomination, key
                        ) in session.session_denominations"
                        :key="key"
                      >
                        <td>
                          {{
                            session_denomination?.denomination
                              ?.denomination_name
                          }}
                        </td>
                        <td>{{ session_denomination.quantity }}</td>
                        <td v-if="session.session_type == 'Exchange Chips'">
                          {{
                            session_denomination?.to_denomination
                              .denomination_name
                          }}
                        </td>
                        <td v-if="session.session_type == 'Exchange Chips'">
                          {{ session_denomination.to_quantity }}
                        </td>
                        <td>{{ session_denomination.amount }}</td>
                      </tr>
                      <tr>
                        <th class="text-right">Total</th>
                        <th class="text-right">{{ qtyGrandTotal }}</th>
                        <th
                          class="text-right"
                          v-if="session.session_type == 'Exchange Chips'"
                        ></th>
                        <th
                          class="text-right"
                          v-if="session.session_type == 'Exchange Chips'"
                        >
                          {{ toQtyGrandTotal }}
                        </th>
                        <th class="text-right">{{ grandTotal }}</th>
                      </tr>
                      <tr v-if="session.session_denominations == 0">
                        <td colspan="20" style="text-align: center">
                          No Records Found
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
export default {
  name: "Proj-PurchaseOrder.View",
  data() {
    return {
      session: {
        session_id: "",
        session_denominations: [],
      },
      errors: [],
    };
  },

  computed: {
    grandTotal: function () {
      let vm = this;
      let grand_total = this.session.session_denominations
        .reduce((prev, cur) => +prev + +cur.amount, 0)
        .toFixed(2);
      vm.grand_total = grand_total;
      return grand_total;
    },
    qtyGrandTotal: function () {
      let vm = this;
      let qty_grand_total = this.session.session_denominations
        .reduce((prev, cur) => +prev + +cur.quantity, 0)
        .toFixed(2);
      vm.qty_grand_total = qty_grand_total;
      return qty_grand_total;
    },
    toQtyGrandTotal: function () {
      let vm = this;
      let to_qty_grand_total = this.session.session_denominations
        .reduce((prev, cur) => +prev + +cur.to_quantity, 0)
        .toFixed(2);
      vm.to_qty_grand_total = to_qty_grand_total;
      return to_qty_grand_total;
    },
  },

  mounted() {
    let vm = this;
    vm.showSession();
  },

  methods: {
    showSession() {
      let vm = this;
      vm.session.session_id = vm.$route.params.session_id;
      vm.$store
        .dispatch("post", { uri: "showViewSession", data: vm.session })
        .then(function (response) {
          vm.session = response.data.data;
        })
        .catch(function (error) {
          vm.errors = error.response.data.errors;
          vm.$store.dispatch("error", error.response.data.message);
        });
    },
  },
};
</script>
