<template>
	<div class="content-wrapper">
		<nav aria-label="breadcrumb">
			<ol class="breadcrumb">
				<li class="breadcrumb-item">
					<a href="#">
						<i class="fa fa-home"></i>
						Dashboard
					</a>
				</li>
				<li class="breadcrumb-item active">Notification</li>
				<li class="breadcrumb-right">
					<router-link to="/notifications" class="btn btn-sm btn-success">
						Notifications
					</router-link>
				</li>
			</ol>
		</nav>
		<section class="content">
			<div class="container-fluid">
				<div class="row justify-content-md-center">
					<div class="col-sm-12">
						<form class="card" @submit.prevent="submitForm()">
							<div class="card-header">
								<h3 class="card-title">New Notification</h3>
							</div>
							<div class="card-body">
								<div class="row">
									<div class="col-sm-6">
										<div class="form-group">
											<label for="subject">Subject </label><span class="text-danger"> *</span>
											<input type="text" class="form-control form-control-sm"
												:class="{ 'is-invalid': errors.subject }" placeholder="Subject"
												v-model="notification.subject" />
											<span v-if="errors.subject" class="invalid-feedback">{{ errors.subject[0]
												}}</span>
										</div>
									</div>
									<div class="col-sm-6">
										<div class="form-group">
											<label for="reference_type_id">Branch </label>
											<select v-model="notification.branch_id"
												class="form-control form-control-sm"
												:class="{ 'is-invalid': errors.branch_id }">
												<option value="" disabled>Select Branch</option>
												<option v-for="type in branches" :key="type.branch_id"
													:value="type.branch_id">{{ type.branch_name }}</option>
											</select>
											<span v-if="errors.branch_id" class="invalid-feedback">{{
												errors.branch_id[0] }}</span>
										</div>
									</div>
									<div class="col-sm-4">
										<div class="form-group">
											<label for="image">Image</label>
											<input type="file" class="form-control form-control-sm"
												:class="{ 'is-invalid': errors.image }" @change="onImageChange($event)"
												accept="image/*" />
											<span v-if="errors.image" class="invalid-feedback">{{ errors.image[0]
												}}</span>
										</div>
									</div>
									<div class="col-sm-4">
										<div class="form-group">
											<label for="frequency">Frequency </label><span class="text-danger"> *</span>
											<select v-model="notification.frequency"
												class="form-control form-control-sm"
												:class="{ 'is-invalid': errors.frequency }">
												<option value="" disabled>Select Frequency</option>
												<option value="Once">Once</option>
												<option value="Daily">Daily</option>
												<option value="Weekly">Weekly</option>
												<option value="Monthly">Monthly</option>
												<option value="Yearly">Yearly</option>
											</select>
											<span v-if="errors.frequency" class="invalid-feedback">{{
												errors.frequency[0] }}</span>
										</div>
									</div>
									<div class="col-sm-4">
										<div class="form-group">
											<label for="schedule_date_time">Schedule Date & Time </label><span
												class="text-danger"> *</span>
											<input type="datetime-local" class="form-control form-control-sm"
												:class="{ 'is-invalid': errors.schedule_date_time }"
												v-model="notification.schedule_date_time" />
											<span v-if="errors.schedule_date_time" class="invalid-feedback">{{
												errors.schedule_date_time[0] }}</span>
										</div>
									</div>
									<div class="col-sm-12">
										<div class="form-group">
											<label for="message">Message </label> <span class="text-danger"> *</span>
											<textarea rows="3" type="text" class="form-control form-control-sm"
												:class="{ 'is-invalid': errors.message }" placeholder="Message"
												v-model="notification.message"></textarea>
											<span v-if="errors.message" class="invalid-feedback">{{ errors.message[0]
												}}</span>
										</div>
									</div>


									<!-- <div class="col-sm-12">
                                        <div class="form-group">
                                            <label for="reference_type_id">Reference Type </label> <span class="text-danger"> *</span>
                                            <select v-model="notification.reference_type_id" class="form-control form-control-sm" :class="{'is-invalid': errors.reference_type_id}">
                                                <option value="" disabled>Select Reference Type</option>
                                                <option v-for="type in referenceTypes" :key="type.reference_type_id" :value="type.reference_type_id">{{ type.reference_type }}</option>
                                            </select>
                                            <span v-if="errors.reference_type_id" class="invalid-feedback">{{ errors.reference_type_id[0] }}</span>
                                        </div>
                                    </div> -->

									<!-- <div class="col-sm-12 mt-1">
                                        <label>Groups </label><span class="text-danger"> *</span>
                                    </div> -->
									<!-- <div class="col-sm-12">
                                        <div class="form-check">
                                            <input class="form-check-input" type="checkbox" v-model="all" id="all" @click="selectAll">
                                            <label class="form-check-label" for="all">
                                                Select All
                                            </label>
                                        </div>
                                    </div> -->
									<!-- <div class="col-sm-6" v-for="(group,key) in groups" :key="key">
                                        <div class="form-check">
                                            <input class="form-check-input" type="checkbox" :id="group.group_id" :value="group.group_id" v-model="grps">
                                            <label class="form-check-label" :for="group.group_id">
                                                {{ group.group }}
                                            </label>
                                        </div>
                                    </div> -->
									<div class="col-sm-12" v-if="errors.groups">
										<span style="color:red">{{ errors.groups[0] }}</span>
									</div>
								</div>
							</div>
							<div class="card-footer">
								<div class="float-right">
									<button class="btn btn-sm btn-outline-danger" type="button" @click="discard()"><i
											class="fas fa-ban"></i> Discard</button>
									<button class="btn btn-sm btn-outline-success" type="submit">
										<span v-if="status"> <i class="fas fa-save"></i> Submit </span>
										<span v-else> <i class="fas fa-save"></i> Update </span>
									</button>
								</div>
							</div>
						</form>
					</div>
				</div>
			</div>
		</section>
	</div>
</template>

<script>

import moment from "moment";
export default {
	name: "Notification",

	data() {
		return {
			status: true,
			column: 'col-8',
			meta: {
				search: "",
				order_by: "desc",
				keyword: "notification_id",
				per_page: 10,
				totalRows: 0,
				page: 1,
				lastPage: 1,
				from: 1,
				maxPage: 1,
				trashed: false,
			},
			notification: {
				notification_id: "",
				user_id: this.$store.getters.user.user_id,
				// branch_id: this.$store.getters.user.branch_id,
				branch_id: "",
				// notification_type: "All",
				// reference_type_id: "",
				subject: "",
				message: "",
				// groups: [],
				image: "",
				frequency: "",
				schedule_date_time: ""
			},
			all: false,
			grps: [],
			groups: [],
			notifications: [],
			referenceTypes: [],
			get_notification: [],
			errors: [],
			branches: [],
		};
	},

	beforeRouteEnter(to, from, next) {
		next((vm) => {
			vm.getBranches();
		});
	},


	mounted() {
		let vm = this;
		vm.notification.schedule_date_time = moment().format("yyyy-MM-DD HH:mm");
	},

	methods: {
		getAttachmentUrl(image) {
			return `${image}`;
		},
		submitForm() {
			let vm = this;
			if (vm.status) {
				vm.create();
			} else {
				vm.update();
			}
		},

		edit(notification) {
			let vm = this;
			(vm.status = false),
				(vm.notification = notification),
				vm.grps = [];
			vm.notification.notification_groups.map(function (element) {
				vm.grps.push(element.group_id)
			})
		},

		update() {
			let vm = this;
			let loader = vm.$loading.show();
			vm.notification.user_id = vm.$store.getters.user.user_id;
			vm.notification.groups = vm.grps;
			let uri = { uri: "updateNotification", data: vm.notification };
			vm.$store
				.dispatch("post", uri)
				.then(function () {
					loader.hide();
					vm.$store.dispatch("success", "Notification is successfully updated");
					vm.discard();
				})
				.catch(function (error) {
					loader.hide();
					vm.errors = error.response.data.errors;
					vm.$store.dispatch("error", error.response.data.message);
				});
		},

		create() {
			let vm = this;
			let loader = vm.$loading.show();
			vm.notification.user_id = vm.$store.getters.user.user_id;
			vm.notification.groups = vm.grps;

			let formData = new FormData();
			formData.append('user_id', vm.notification.user_id);
			formData.append('branch_id', vm.notification.branch_id);
			formData.append('subject', vm.notification.subject);
			formData.append('message', vm.notification.message);
			formData.append('frequency', vm.notification.frequency);
			formData.append('schedule_date_time', vm.notification.schedule_date_time);

			if (vm.notification.image) {
				formData.append('image', vm.notification.image);
			}

			let uri = { uri: "addNotification", data: formData };
			vm.$store
				.dispatch("post", uri)
				.then(function () {
					loader.hide();
					vm.$store.dispatch("success", "Notification is successfully created");
					// vm.$store.dispatch("post", { uri: "sendNotification", data: response.data.data });
					vm.$router.push("/notifications");

				})
				.catch(function (error) {
					loader.hide();
					vm.errors = error.response.data.errors;
					vm.$store.dispatch("error", error.response.data.message);
				});
		},

		discard() {
			let vm = this;
			vm.notification.notification_type = "All";
			vm.notification.subject = "";
			vm.notification.branch_id = "";
			vm.notification.message = "";
			vm.notification.image = "";
			vm.notification.frequency = "";
			vm.notification.schedule_date_time = "";
			vm.errors = [];
			vm.status = true;
			vm.grps = [];
			vm.all = false;
			vm.index();
		},

		onPageChange(page) {
			this.meta.page = page;
			this.index();
		},

		search() {
			let vm = this;
			vm.meta.page = 1;
			vm.index();

		},
		getGroups() {
			let vm = this;
			vm.$store.dispatch("post", { uri: "getGroups" })
				.then(function (response) {
					vm.groups = response.data.data;
				})
				.catch(function (error) {
					vm.errors = error.response.data.errors;
					vm.$store.dispatch("error", error.response.data.message);
				});
		},
		getBranches() {
			let vm = this;
			vm.$store.dispatch("post", { uri: "getBranches" })
				.then(function (response) {
					vm.branches = response.data.data;
				})
				.catch(function (error) {
					vm.errors = error.response.data.errors;
					vm.$store.dispatch("error", error.response.data.message);
				});
		},

		onImageChange(e) {
			let vm = this;
			vm.notification.image = e.target.files[0]
		},

		getReferenceTypes() {
			let vm = this;
			vm.$store.dispatch("post", { uri: "getReferenceTypes" })
				.then(function (response) {
					vm.referenceTypes = response.data.data;
				})
				.catch(function (error) {
					vm.errors = error.response.data.errors;
					vm.$store.dispatch("error", error.response.data.message);
				});
		},

		selectAll() {
			let vm = this;
			if (vm.grps.length == 0) {
				vm.groups.map(function (element) {
					vm.grps.push(element.group_id)
				});
			}
			else {
				vm.grps = [];
			}
		}
	},
};
</script>
