import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import CKEditor from "@ckeditor/ckeditor5-vue";
import VueLoading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
var moment = require("moment");

const app = createApp(App);
app.use(store);
app.use(router);
app.use(VueLoading);
app.use(CKEditor);

app.config.globalProperties.$formatDate = (date, format) => {
  const options = {};
  if (date == null || date == "") {
    return null;
  } else if (format === "long") {
    options.weekday = "long";
    options.year = "numeric";
    options.month = "long";
    options.day = "numeric";
    return new Date(date).toLocaleDateString(undefined, options);
  } else if (format === "short") {
    options.year = "numeric";
    options.month = "short";
    options.day = "numeric";
    return new Date(date).toLocaleDateString(undefined, options);
  } else if (format === "india") {
    return moment(date).format("DD-MM-YY hh:mm A");
  } else if (format === "time") {
    return moment(date).format("HH:mm:ss");
  } else if (format === "indian_time") {
    return moment(date).format("hh:mm A");
  } else {
    return new Date(date).toLocaleDateString(undefined, options);
  }
};

app.config.globalProperties.$mask = (mobile_no) => {
  return mobile_no.substring(0, 2) + "xxxxxx" + mobile_no.substring(8);
};

app.config.globalProperties.$formatCurrency = (amount) => {
  return new Intl.NumberFormat("en-IN", {
    style: "currency",
    currency: "INR",
  }).format(amount);
};

app.directive("can", {
  mounted: function (el, binding) {
    let permissions = store.getters.permissions;
    if (permissions && permissions.length != 0) {
      let permission = permissions.filter(function (el) {
        return (
          el.ability.ability == binding.value &&
          el.ability?.module?.application_type == "Web"
        );
      });
      if (!permission[0]) {
        el.parentNode.removeChild(el);
      }
    } else {
      el.parentNode.removeChild(el);
    }
  },
});

app.mount("#app");
