<template>
  <div class="content-wrapper">
    <nav aria-label="breadcrumb">
      <ol class="breadcrumb">
        <li class="breadcrumb-item">
          <a href="#">
            <i class="fa fa-home"></i>
            Dashboard
          </a>
        </li>
        <li class="breadcrumb-item active">Expenses</li>
        <li class="breadcrumb-right" v-can="'expenses.create'">
          <router-link to="/expenses/create" class="btn btn-sm btn-success">
            <i class="fa fa-plus"></i>
            New Expense
          </router-link>
        </li>
      </ol>
    </nav>
    <section class="content">
      <div class="container-fluid">
        <div class="row justify-content-md-center">
          <div class="col-sm-12">
            <div class="card">
              <div class="card-header">
                <h3 class="card-title">Expenses</h3>
                <div class="card-tools" style="margin-top: -5px">
                  <button class="btn btn-sm btn-primary mr-2" @click="search">
                    <i class="fa fa-search mr-1"></i>Search
                  </button>
                  <button class="btn btn-sm btn-danger" @click="resetSearch">
                    <i class="fa fa-times mr-1"></i>Reset
                  </button>
                </div>
                <!-- <ul class="nav nav-pills ml-auto p-2">
                                    <li class="nav-item">
                                        <button class="nav-link btn text-white btn-sm btn-primary mr-2" @click="search"><i class="fa fa-search mr-1"></i>Search</button>
                                    </li>
                                    <li class="nav-item">
                                        <button class="nav-link btn text-white btn-sm btn-danger" @click="resetSearch"><i class="fa fa-times mr-1"></i>Reset</button>
                                    </li>

                                </ul> -->
              </div>
              <div class="card-body">
                <div class="row">
                  <div class="col">
                    <div class="form-group">
                      <label for="from_date">From Date</label>
                      <input
                        class="form-control form-control-sm"
                        type="date"
                        placeholder="Search"
                        v-model="meta.from_date"
                      />
                    </div>
                  </div>
                  <div class="col">
                    <div class="form-group">
                      <label for="from_date">To Date</label>
                      <input
                        class="form-control form-control-sm"
                        type="date"
                        placeholder="Search"
                        v-model="meta.to_date"
                      />
                    </div>
                  </div>
                  <div class="col">
                    <div class="form-group">
                      <label for="account_id">Account</label>
                      <select
                        class="form-control form-control-sm"
                        :class="{ 'is-invalid': errors.account_id }"
                        v-model="meta.account_id"
                      >
                        <option value="">Select Account</option>
                        <option
                          v-for="(account, key) in accounts"
                          :key="key"
                          :value="account.account_id"
                        >
                          {{ account.account }}
                        </option>
                      </select>
                    </div>
                  </div>
                  <div class="col">
                    <div class="form-group">
                      <label for="category_id">Category</label>
                      <!-- <select class="form-control form-control-sm" :class="{'is-invalid': errors.category_id}" v-model="meta.category_id"  @change="search">
                                                <option value="">Select Category</option>
                                                <option v-for="(category,key) in categories" :key="key" :value="category.category_id">{{ category.category}}</option>
                                            </select> -->
                      <search
                        :class="{ 'is-invalid': errors.category_id }"
                        :customClass="{ 'is-invalid': errors.category_id }"
                        :initialize="meta.category_id"
                        id="category_id"
                        label="category"
                        placeholder="Select Category"
                        :data="categories"
                        @input="selectCategory($event)"
                      >
                      </search>
                    </div>
                  </div>
                  <div class="col">
                    <div class="form-group">
                      <label for="payment_mode_id">Payment Mode</label>
                      <select
                        class="form-control form-control-sm"
                        :class="{ 'is-invalid': errors.payment_mode_id }"
                        v-model="meta.payment_mode_id"
                      >
                        <option value="">Select Payment Mode</option>
                        <option
                          v-for="(payment_mode, key) in payment_modes"
                          :key="key"
                          :value="payment_mode.payment_mode_id"
                        >
                          {{ payment_mode.payment_mode }}
                        </option>
                      </select>
                    </div>
                  </div>
                  <div class="col">
                    <div class="form-group">
                      <label for="user_id">User</label>
                      <!-- <select class="form-control form-control-sm" :class="{'is-invalid': errors.user_id}" v-model="meta.user_id"  @change="search">
                                                <option value="">Select User</option>
                                                <option v-for="(user,key) in users" :key="key" :value="user.user_id">{{ user.name}}</option>
                                            </select> -->
                      <search
                        :class="{ 'is-invalid': errors.user_id }"
                        :customClass="{ 'is-invalid': errors.user_id }"
                        :initialize="meta.user_id"
                        id="user_id"
                        label="name"
                        placeholder="Select User"
                        :data="users"
                        @input="selectUser($event)"
                      >
                      </search>
                    </div>
                  </div>
                  <!-- <div class="col mt-auto">
                                        <div class="form-group">
                                            <label ></label>
                                        <button class="btn btn-primary " @click="search">Search</button>
                                        </div>
                                    </div> -->
                </div>
                <div class="table-responsive" style="height: 390px">
                  <table
                    class="table text-nowrap table-bordered table-striped table-sm table-head-fixed"
                  >
                    <thead>
                      <tr>
                        <th class="text-center">#</th>
                        <th @click="sort('voucher_no')">
                          Voucher No
                          <span>
                            <i
                              v-if="
                                meta.keyword == 'voucher_no' &&
                                meta.order_by == 'asc'
                              "
                              class="fas fa-sort-up"
                            ></i>
                            <i
                              v-else-if="
                                meta.keyword == 'voucher_no' &&
                                meta.order_by == 'desc'
                              "
                              class="fas fa-sort-down"
                            ></i>
                            <i v-else class="fas fa-sort"></i>
                          </span>
                        </th>
                        <th>Voucher Type</th>
                        <th>Voucher Date</th>
                        <th>Reminder Date</th>
                        <th>Vendor</th>
                        <th>Category</th>
                        <th>Account</th>
                        <th>Payment Mode</th>
                        <th class="text-right">Amount</th>
                        <th class="text-center">Status</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr
                        class="pointer"
                        v-for="(expense, key) in expenses"
                        :key="key"
                        @click="viewExpense(expense)"
                      >
                        <td class="text-center">{{ meta.from + key }}</td>
                        <td>{{ expense.voucher_no }}</td>
                        <td>{{ expense.type }}</td>
                        <td>{{ expense.voucher_date }}</td>
                        <td>{{ expense.reminder_date }}</td>
                        <td>{{ expense.vendor?.vendor_name }}</td>
                        <td>{{ expense?.category.category }}</td>
                        <td>{{ expense?.account.account }}</td>
                        <td>{{ expense?.payment_mode.payment_mode }}</td>
                        <td class="text-right">
                          <span v-if="expense.type == 'Expense'">
                            {{ expense?.payable_amount }}</span
                          >
                          <span v-if="expense.type == 'TDS'">
                            {{ expense?.tds_amount }}</span
                          >
                        </td>
                        <td class="text-center">
                          <span
                            class="badge bg-success"
                            v-if="expense.payment_status == 'Approved'"
                            >{{ expense.payment_status }}</span
                          >
                          <span
                            class="badge bg-warning"
                            v-if="expense.payment_status == 'Pending'"
                            >{{ expense.payment_status }}</span
                          >
                        </td>
                      </tr>
                      <tr v-if="expenses.length <= 0">
                        <td colspan="12" class="text-center">
                          No Records Found
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              <div class="card-footer">
                <div class="float-right">
                  <Pagination
                    :maxPage="meta.maxPage"
                    :totalPages="meta.lastPage"
                    :currentPage="meta.page"
                    @pagechanged="onPageChange"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import Pagination from "@/components/Pagination.vue";
import Search from "@/components/Search.vue";
export default {
  name: "User.Index",
  components: { Pagination, Search },
  data() {
    return {
      status: true,
      meta: {
        branch_id: this.$store.getters.user.branch_id,
        from_date: "",
        to_date: "",
        category_id: "",
        account_id: "",
        payment_mode_id: "",
        user_id: "",
        order_by: "desc",
        keyword: "voucher_no",
        per_page: 50,
        totalRows: 0,
        page: 1,
        lastPage: 1,
        from: 1,
        maxPage: 1,
      },
      categories: [],
      accounts: [],
      payment_modes: [],
      users: [],
      expenses: [],
      errors: [],
    };
  },

  beforeRouteEnter(to, from, next) {
    next((vm) => {
      if (from.name == "Expenses.View" && vm.$store.getters.meta) {
        vm.meta = vm.$store.getters.meta;
      } else {
        vm.$store.dispatch("setMeta", {});
      }
      vm.index();
      vm.getAccounts();
    });
  },

  methods: {
    index() {
      let vm = this;
      vm.$store
        .dispatch("post", { uri: "paginateExpenses", data: vm.meta })
        .then(function (response) {
          vm.expenses = response.data.data;
          vm.meta.totalRows = response.data.meta.total;
          vm.meta.lastPage = response.data.meta.last_page;
          vm.meta.from = response.data.meta.from;
          vm.meta.maxPage = vm.meta.lastPage >= 3 ? 3 : vm.meta.lastPage;
        })
        .catch(function (error) {
          vm.errors = error.response.data.errors;
          vm.$store.dispatch("error", error.response.data.message);
        });
    },

    getAccounts() {
      let vm = this;
      if (vm.$store.getters.accounts.length > 0) {
        vm.accounts = vm.$store.getters.accounts;
        vm.getCategories();
      } else {
        vm.$store
          .dispatch("post", { uri: "getAccounts" })
          .then(function (response) {
            vm.accounts = response.data.data;
            vm.$store.dispatch("setAccounts", vm.accounts);
            vm.getCategories();
          })
          .catch(function (error) {
            vm.errors = error.response.data;
            vm.$store.dispatch("error", error.response.data.message);
          });
      }
    },

    getCategories() {
      let vm = this;
      if (vm.$store.getters.categories.length > 0) {
        vm.categories = vm.$store.getters.categories;
        vm.getPaymentModes();
      } else {
        vm.$store
          .dispatch("post", { uri: "getCategories" })
          .then(function (response) {
            vm.categories = response.data.data;
            vm.$store.dispatch("setCategories", vm.categories);
            vm.getPaymentModes();
          })
          .catch(function (error) {
            vm.errors = error.response.data;
            vm.$store.dispatch("error", error.response.data.message);
          });
      }
    },

    getPaymentModes() {
      let vm = this;
      if (vm.$store.getters.payment_modes.length > 0) {
        vm.payment_modes = vm.$store.getters.payment_modes;
        vm.getBranchUsers();
      } else {
        vm.$store
          .dispatch("post", { uri: "paymentModes" })
          .then(function (response) {
            vm.payment_modes = response.data.data;
            vm.$store.dispatch("setPaymentModes", vm.payment_modes);
            vm.getBranchUsers();
          })
          .catch(function (error) {
            vm.errors = error.response.data;
            vm.$store.dispatch("error", error.response.data.message);
          });
      }
    },

    getBranchUsers() {
      let vm = this;
      if (vm.$store.getters.branch_users.length > 0) {
        vm.users = vm.$store.getters.branch_users;
      } else {
        let uri = { uri: "getBranchUsers", data: vm.meta };
        vm.$store
          .dispatch("post", uri)
          .then(function (response) {
            vm.users = response.data.data;
            vm.$store.dispatch("setBranchUsers", vm.users);
          })
          .catch(function (error) {
            vm.errors = error.response.data.errors;
            vm.$store.dispatch("error", error.response.data.message);
          });
      }
    },

    editExpense(expense) {
      let vm = this;
      this.$store.dispatch("setMeta", vm.meta);
      this.$router.push("/expenses/" + expense.expense_id + "/edit");
    },

    viewExpense(expense) {
      let vm = this;
      this.$store.dispatch("setMeta", vm.meta);
      this.$router.push("/expenses/" + expense.expense_id);
    },

    deleteExpense(expense) {
      if (confirm("Do you want to continue... ?")) {
        let vm = this;
        let loader = vm.$loading.show();
        let uri = { uri: "deleteExpense", data: expense };
        vm.$store
          .dispatch("post", uri)
          .then(function () {
            loader.hide();
            vm.$store.dispatch("success", "Expense is successfully deleted");
            vm.search();
          })
          .catch(function (error) {
            loader.hide();
            vm.errors = error.response.data.errors;
            vm.$store.dispatch("error", error.response.data.message);
          });
      }
    },

    onPageChange(page) {
      this.meta.page = page;
      this.index();
    },

    search() {
      let vm = this;
      vm.meta.page = 1;
      vm.index();
    },
    selectCategory(category) {
      this.meta.category_id = category;
      // this.search()
    },
    selectUser(user) {
      this.meta.user_id = user;
      // this.search()
    },
    resetSearch() {
      let vm = this;
      vm.meta.from_date = "";
      vm.meta.to_date = "";
      vm.meta.account_id = "";
      vm.meta.category_id = "";
      vm.meta.payment_mode_id = "";
      vm.meta.user_id = "";
      vm.errors = [];
      vm.index();
    },
    sort(field) {
      this.meta.keyword = field;
      this.meta.order_by = this.meta.order_by == "asc" ? "desc" : "asc";
      this.index();
    },
  },
};
</script>
