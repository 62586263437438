<template>
	<div class="content-wrapper">
		<nav aria-label="breadcrumb">
			<ol class="breadcrumb">
				<li class="breadcrumb-item">
					<a href="#">
						<i class="fa fa-home"></i>
						Dashboard
					</a>
				</li>
				<li class="breadcrumb-item active">Notifications</li>
				<li class="breadcrumb-right">
					<router-link v-can="'notifications.create'" to="/notification/create"
						class="btn btn-sm btn-success">
						Add Notification
					</router-link>
				</li>
			</ol>
		</nav>
		<section class="content">
			<div class="container-fluid">
				<div class="row justify-content-md-center">
					<div class="col-sm-12">
						<div class="card">
							<div class="card-header">
								<h3 class="card-title">Notifications</h3>
							</div>
							<div class="card-body">
								<div class="form-group">
									<input class="form-control form-control-sm" type="text" placeholder="Search"
										v-model="meta.search" @keyup="search" v-if="!meta.trashed" />
								</div>
								<table class="table table-responsive-sm table-bordered table-striped table-sm">
									<thead>
										<tr>
											<th class="text-center">#</th>
											<th>Subject</th>
											<th>Branch</th>
											<th>Scheduled Time</th>
											<th>Frequency</th>
											<th>Image</th>
											<th class="text-center" v-if="get_notification.length">Action</th>
										</tr>
									</thead>
									<tbody>
										<tr v-for="(notification, key) in notifications" :key="key">
											<td class="text-center">{{ meta.from + key }}</td>
											<td>{{ notification.subject }}</td>
											<td>{{ notification?.branch?.branch_code }}</td>
											<td>{{ notification.schedule_date_time }}</td>
											<td>{{ notification.frequency }}</td>
											<td>
												<a :href="getAttachmentUrl(notification.image)" target="_blank">
													{{ notification.image_data }}
												</a>
											</td>
											<td class="text-center" v-if="get_notification.length">
												<button class="btn btn-xs btn-outline-primary" data-toggle="tooltip"
													data-placement="bottom" title="View Notification"
													@click="viewNotification(notification)">
													<i class="fas fa-eye"></i>
												</button>
												<button v-can="'notifications.instant'"
													class="btn btn-xs btn-outline-warning" data-toggle="tooltip"
													data-placement="bottom" title="Instant Notification"
													@click="instantNotification(notification)">
													<i class="fas fa-bell"></i>
												</button>
												<button v-can="'notifications.delete'"
													class="btn btn-xs btn-outline-danger" data-toggle="tooltip"
													data-placement="bottom" title="Delete"
													@click="deleteNotification(notification)">
													<i class="fas fa-trash-alt"></i>
												</button>
											</td>
										</tr>
										<tr v-if="notifications.length <= 0">
											<td colspan="7" style="text-align: center;">No Records Found</td>
										</tr>
									</tbody>
								</table>
							</div>
							<div class="card-footer">
								<div class="float-right">
									<Pagination :maxPage="meta.maxPage" :totalPages="meta.lastPage"
										:currentPage="meta.page" @pagechanged="onPageChange" />
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</section>
	</div>
</template>

<script>
import Pagination from "@/components/Pagination.vue";
import moment from "moment";
export default {
	name: "Notification",
	components: { Pagination },
	data() {
		return {
			status: true,
			column: "col-8",
			meta: {
				search: "",
				order_by: "desc",
				keyword: "notification_id",
				per_page: 10,
				totalRows: 0,
				page: 1,
				lastPage: 1,
				from: 1,
				maxPage: 1,
				trashed: false,
			},
			notification: {
				notification_id: "",
				user_id: this.$store.getters.user.user_id,
				branch_id: "",
				subject: "",
				message: "",
				image: "",
				frequency: "",
				schedule_date_time: "",
			},
			all: false,
			grps: [],
			groups: [],
			notifications: [],
			referenceTypes: [],
			get_notification: [],
			errors: [],
			branches: [],
		};
	},

	mounted() {
		let vm = this;
		vm.index();
		vm.getBranches();
		vm.notification.schedule_date_time = moment().format("yyyy-MM-DD HH:mm");

		this.get_notification = this.$store.getters.permissions.filter(function (element) {
			return element.ability.ability.includes("notifications.instant") || element.ability.ability.includes("notifications.delete");
		});
	},

	methods: {
		getAttachmentUrl(image) {
			return `${image}`;
		},
		convertDateFormat(date) {
			return moment(date).format("YYYY-MM-DD HH:mm");
		},
		submitForm() {
			let vm = this;
			if (vm.status) {
				vm.create();
			} else {
				vm.update();
			}
		},

		index() {
			let vm = this;
			vm.$store
				.dispatch("post", { uri: "paginateNotifications", data: vm.meta })
				.then(function (response) {
					vm.notifications = response.data.data;
					vm.meta.totalRows = response.data.meta.total;
					vm.meta.lastPage = response.data.meta.last_page;
					vm.meta.from = response.data.meta.from;
					vm.meta.maxPage = vm.meta.lastPage >= 3 ? 3 : vm.meta.lastPage;
					vm.getGroups();
				})
				.catch(function (error) {
					vm.errors = error.response.data.errors;
					vm.$store.dispatch("error", error.response.data.message);
				});
		},

		edit(notification) {
			let vm = this;
			(vm.status = false), (vm.notification = notification), (vm.grps = []);
			vm.notification.notification_groups.map(function (element) {
				vm.grps.push(element.group_id);
			});
		},

		update() {
			let vm = this;
			let loader = vm.$loading.show();
			vm.notification.user_id = vm.$store.getters.user.user_id;
			vm.notification.groups = vm.grps;
			let uri = { uri: "updateNotification", data: vm.notification };
			vm.$store
				.dispatch("post", uri)
				.then(function () {
					loader.hide();
					vm.$store.dispatch("success", "Notification is successfully updated");
					vm.discard();
				})
				.catch(function (error) {
					loader.hide();
					vm.errors = error.response.data.errors;
					vm.$store.dispatch("error", error.response.data.message);
				});
		},


		sendNotification(notification) {
			if (confirm("Do you want to continue... ?")) {
				let vm = this;
				let loader = vm.$loading.show();
				let uri = { uri: "sendNotification", data: notification };
				vm.$store
					.dispatch("post", uri)
					.then(function () {
						loader.hide();
						vm.$store.dispatch("success", "Notification is successfully sent");
					})
					.catch(function (error) {
						loader.hide();
						vm.errors = error.response.data.errors;
						vm.$store.dispatch("error", error.response.data.message);
					});
			}
		},

		deleteNotification(notification) {
			if (confirm("Do you want to continue... ?")) {
				let vm = this;
				let loader = vm.$loading.show();
				let uri = { uri: "deleteNotification", data: notification };
				vm.$store
					.dispatch("post", uri)
					.then(function (response) {
						loader.hide();
						vm.$store.dispatch("success", response.data.message);
						vm.discard();
					})
					.catch(function (error) {
						loader.hide();
						vm.errors = error.response.data.errors;
						vm.$store.dispatch("error", error.response.data.message);
					});
			}
		},
		instantNotification(notification) {
			if (confirm("Are you sure you want to send the notification?")) {
				let vm = this;
				let loader = vm.$loading.show();
				let uri = { uri: "instantNotification", data: notification };
				vm.$store
					.dispatch("post", uri)
					.then(function (response) {
						loader.hide();
						vm.$store.dispatch("success", response.data.message);
						vm.discard();
					})
					.catch(function (error) {
						loader.hide();
						vm.errors = error.response.data.errors;
						vm.$store.dispatch("error", error.response.data.message);
					});
			}
		},

		discard() {
			let vm = this;
			vm.notification.notification_type = "All";
			vm.notification.subject = "";
			vm.notification.message = "";
			vm.notification.image = "";
			vm.notification.frequency = "";
			vm.notification.schedule_date_time = "";
			vm.errors = [];
			vm.status = true;
			vm.grps = [];
			vm.all = false;
			vm.index();
		},

		onPageChange(page) {
			this.meta.page = page;
			this.index();
		},

		search() {
			let vm = this;
			vm.meta.page = 1;
			vm.index();
		},
		getGroups() {
			let vm = this;
			vm.$store
				.dispatch("post", { uri: "getGroups" })
				.then(function (response) {
					vm.groups = response.data.data;
				})
				.catch(function (error) {
					vm.errors = error.response.data.errors;
					vm.$store.dispatch("error", error.response.data.message);
				});
		},
		getBranches() {
			let vm = this;
			vm.$store
				.dispatch("post", { uri: "getBranches" })
				.then(function (response) {
					vm.branches = response.data.data;
				})
				.catch(function (error) {
					vm.errors = error.response.data.errors;
					vm.$store.dispatch("error", error.response.data.message);
				});
		},

		onImageChange(e) {
			let vm = this;
			vm.notification.image = e.target.files[0];
		},

		getReferenceTypes() {
			let vm = this;
			vm.$store
				.dispatch("post", { uri: "getReferenceTypes" })
				.then(function (response) {
					vm.referenceTypes = response.data.data;
				})
				.catch(function (error) {
					vm.errors = error.response.data.errors;
					vm.$store.dispatch("error", error.response.data.message);
				});
		},

		selectAll() {
			let vm = this;
			if (vm.grps.length == 0) {
				vm.groups.map(function (element) {
					vm.grps.push(element.group_id);
				});
			} else {
				vm.grps = [];
			}
		},

		viewNotification(notification) {
			alert(notification.message)
		}
	},
};
</script>
