<template>
	<form @submit.prevent="submitTransaction">
		<div class="card-body">
			<div class="row mt-2">
				<div class="col-sm-6">
					<div class="form-group">
						<label for="customer_id">Customer * </label>
						<Customer :class="{ 'is-invalid': errors.customer_id }"
							:customClass="{ 'is-invalid': errors.customer_id }" :initialize="transaction.customer_name"
							@selectCustomer="selectCustomer($event)" ref="customer_id"></Customer>
						<span v-if="errors.customer_id" class="invalid-feedback">{{
							errors.customer_id[0]
						}}</span>
					</div>
				</div>
				<div class="col-sm-2">
					<div class="form-group">
						<label for="cash_limit">Cash Limit *</label>
						<input disabled type="text" class="form-control form-control-sm"
							:class="{ 'is-invalid': errors.cash_limit }" placeholder="Cash Limit"
							v-model="transaction.cash_limit" />
						<span v-if="errors.cash_limit" class="invalid-feedback">{{
							errors.cash_limit[0]
						}}</span>
					</div>
				</div>
				<div class="col-sm-2">
					<div class="form-group">
						<label for="wallet_balance">Wallet Balance *</label>
						<input disabled type="text" class="form-control form-control-sm"
							:class="{ 'is-invalid': errors.wallet_balance }" placeholder="Wallet Balance"
							v-model="transaction.wallet_balance" />
						<span v-if="errors.wallet_balance" class="invalid-feedback">{{
							errors.wallet_balance[0]
						}}</span>
					</div>
				</div>
				<div class="col-sm-2">
					<div class="form-group">
						<label for="transaction_date_time">Transaction Date & Time *</label>
						<input disabled type="datetime-local" class="form-control form-control-sm"
							:class="{ 'is-invalid': errors.transaction_date_time }"
							v-model="transaction.transaction_date_time" />
						<span v-if="errors.transaction_date_time" class="invalid-feedback">{{
							errors.transaction_date_time[0] }}</span>
					</div>
				</div>
				<div class="col-sm-3">
					<div class="form-group">
						<label for="amount">Amount *</label>
						<input type="number" class="form-control form-control-sm"
							:class="{ 'is-invalid': errors.amount }" placeholder="Amount" v-model="transaction.amount"
							ref="amount" @blur="calculateTDS" />

						<span v-if="errors.amount" class="invalid-feedback">{{
							errors.amount[0]
						}}</span>
					</div>
				</div>
				<div class="col-sm-3">
					<div class="form-group">
						<label for="payment_mode_id">Payment Mode</label>
						<select class="form-control form-control-sm" :class="{ 'is-invalid': errors.payment_mode_id }"
							v-model="transaction.payment_mode_id">
							<option value="">Select Payment Mode</option>
							<option v-for="(payment_mode, key) in payment_modes" :key="key"
								:value="payment_mode.payment_mode_id">
								{{ payment_mode.payment_mode }}
							</option>
						</select>
						<span v-if="errors.payment_mode_id" class="invalid-feedback">{{
							errors.payment_mode_id[0]
						}}</span>
					</div>
				</div>
				<div class="col-sm-2">
					<div class="form-group">
						<label for="reference_id">Reference No </label>
						<input type="text" class="form-control form-control-sm"
							:class="{ 'is-invalid': errors.reference_id }" placeholder="Reference No"
							v-model="transaction.reference_id" />
						<span v-if="errors.reference_id" class="invalid-feedback">{{
							errors.reference_id[0]
						}}</span>
					</div>
				</div>
				<div class="col-sm-2">
					<div class="form-group">
						<label for="tds">TDS ({{ organization?.tds }} %)*</label>
						<input disabled type="number" class="form-control form-control-sm"
							:class="{ 'is-invalid': errors.tds }" placeholder="TDS" v-model="transaction.tds" />
						<span v-if="errors.tds" class="invalid-feedback">{{
							errors.tds[0]
						}}</span>
					</div>
				</div>
				<div class="col-sm-2">
					<div class="form-group">
						<label for="total_amount">Payable Amount *</label>
						<input disabled type="number" class="form-control form-control-sm"
							:class="{ 'is-invalid': errors.total_amount }" placeholder="Payable Amount"
							v-model="transaction.total_amount" />
						<span v-if="errors.total_amount" class="invalid-feedback">{{
							errors.total_amount[0]
						}}</span>
					</div>
				</div>
				<div class="col-sm-12">
					<div class="form-group">
						<label for="note">Note</label>
						<textarea class="form-control form-control-sm" :class="{ 'is-invalid': errors.note }"
							placeholder="Note" v-model="transaction.note"></textarea>
						<span v-if="errors.note" class="invalid-feedback">{{
							errors.reference_id[0]
						}}</span>
					</div>
				</div>
			</div>
		</div>
		<div class="card-footer">
			<div class="float-right">
				<button class="btn btn-sm btn-outline-success" type="submit">
					<i class="fas fa-save"></i> Submit
				</button>
			</div>
		</div>
	</form>
</template>
<script>
let Customer = require("@/components/Customer.vue").default;
import moment from "moment";
export default {
	name: "Transaction.Create",
	components: { Customer },
	props: {
		session_log_id: {
			default: null,
		},
	},
	data() {
		return {
			transaction: {
				branch_id: this.$store.getters.user.branch_id,
				user_id: this.$store.getters.user.user_id,
				session_log_id: "",
				customer_id: "",
				customer_name: "",
				credit_limit: 0,
				cash_limit: 0,
				wallet_balance: 0,
				transaction_date_time: "",
				transaction_type: "Withdraw",
				amount: "",
				tds: "",
				total_amount: "",
				payment_mode_id: "",
				reference_id: "",
				note: "",
				tds_status: "",
			},
			payment_modes: [],
			organization: {},
			errors: [],
		};
	},

	mounted() {
		let vm = this;
		vm.transaction.session_log_id = vm.session_log_id;
		vm.$refs.customer_id.focus();
		setInterval(vm.setTransactionTime, 1000);
	},

	methods: {
		setTransactionTime() {
			let vm = this;
			vm.transaction.transaction_date_time =
				moment().format("YYYY-MM-DDTHH:mm");
		},

		getPaymentModes() {
			let vm = this;
			if (vm.$store.getters.payment_modes.length > 0) {
				vm.payment_modes = vm.$store.getters.payment_modes;
				vm.getOrganization();
			} else {
				vm.$store
					.dispatch("post", { uri: "paymentModes" })
					.then(function (response) {
						vm.payment_modes = response.data.data;
						vm.$store.dispatch("setPaymentModes", vm.payment_modes);
						vm.discard();
						vm.getOrganization();
					})
					.catch(function (error) {
						vm.errors = error.response.data;
						vm.$store.dispatch("error", error.response.data.message);
					});
			}
		},

		getOrganization() {
			let vm = this;
			vm.$store
				.dispatch("post", { uri: "showOrganization" })
				.then(function (response) {
					vm.organization = response.data.data;
				})
				.catch(function (error) {
					vm.loader.hide();
					vm.errors = error.response.data.errors;
					vm.$store.dispatch("error", error.response.data.message);
				});
		},

		selectCustomer(e) {
			let vm = this;
			const customer = e.target.value;
			vm.transaction.customer_id = customer.customer_id;
			vm.transaction.customer_name = customer.customer_name;
			vm.transaction.credit_limit = customer.credit_limit;
			vm.transaction.tds_status = customer.tds_status;
			vm.getCustomerCashLimit();
			vm.$refs.amount.focus();
		},

		getCustomerCashLimit() {
			let vm = this;
			vm.$store
				.dispatch("post", {
					uri: "transactions/getCustomerCashLimit",
					data: vm.transaction,
				})
				.then(function (response) {
					vm.transaction.cash_limit = response.data["cash_limit"];
					vm.getCustomerWallet();
				})
				.catch(function (error) {
					vm.errors = error.response.data;
					vm.$store.dispatch("error", error.response.data.message);
				});
		},

		getCustomerWallet() {
			let vm = this;
			vm.$store
				.dispatch("post", {
					uri: "transactions/getCustomerWallet",
					data: vm.transaction,
				})
				.then(function (response) {
					vm.transaction.wallet_balance = response.data["opening_balance"];
				})
				.catch(function (error) {
					vm.errors = error.response.data;
					vm.$store.dispatch("error", error.response.data.message);
				});
		},

		async calculateTDS() {
			let vm = this;
			if (
				vm.transaction.amount != 0 &&
				vm.transaction.amount != "" &&
				vm.transaction.customer_id != ""
			) {
				await vm.$store
					.dispatch("post", { uri: "transactions/tds", data: vm.transaction })
					.then(function (response) {
						vm.transaction.tds = response.data;
						vm.transaction.total_amount =
							+vm.transaction.amount - +vm.transaction.tds;
						vm.transaction.total_amount = (
							vm.transaction.total_amount * 1
						).toFixed(0);
					})
					.catch(function (error) {
						vm.errors = error.response.data;
						vm.$store.dispatch("error", error.response.data.message);
					});
			}
		},

		async submitTransaction() {
			let vm = this;
			if(vm.transaction.amount!=vm.transaction.total_amount){
				await vm.calculateTDS();
			}
			let loader = vm.$loading.show();
			let uri = { uri: "submitTransaction", data: vm.transaction };
			await vm.$store
				.dispatch("post", uri)
				.then(function () {
					loader.hide();
					vm.$store.dispatch("success", "Transaction is successfully created");
					vm.discard();
				})
				.catch(function (error) {
					loader.hide();
					vm.errors = error.response.data.errors;
					vm.$store.dispatch("error", error.response.data.message);
				});
		},

		discard() {
			let vm = this;
			vm.transaction.customer_id = "";
			vm.transaction.customer_name = "";
			vm.transaction.credit_limit = 0;
			vm.transaction.cash_limit = 0;
			vm.transaction.wallet_balance = 0;
			vm.transaction.amount = "";
			vm.transaction.tds = "";
			vm.transaction.total_amount = "";
			vm.transaction.payment_mode_id = "";
			vm.transaction.reference_id = "";
			vm.transaction.note = "";
			vm.errors = [];
			vm.$refs.customer_id.focus();
		},
	},
};
</script>
