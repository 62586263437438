<template>
  <div class="content-wrapper">
    <nav aria-label="breadcrumb">
      <ol class="breadcrumb">
        <li class="breadcrumb-item">
          <a href="#">
            <i class="fa fa-home"></i>
            Dashboard
          </a>
        </li>
        <li class="breadcrumb-item active">Customers</li>
        <!-- <div class="ml-auto">
                    <a target="_blank" :href="$store.state.pdfUrl+'customers?display_type=excel'" class="btn btn-sm btn-success float-right"> <i class="fa fa-file-excel"></i> Excel </a>
                </div> -->
      </ol>
    </nav>
    <section class="content">
      <div class="container-fluid">
        <div class="row justify-content-md-center">
          <div class="col-sm-12">
            <div class="card">
              <div class="card-header">
                <h3 class="card-title">Customers</h3>
                <div class="card-tools">
                  <label>Total : {{ meta.totalRows }}</label>
                </div>
              </div>
              <div class="card-body">
                <div class="row">
                  <div class="col-sm-4">
                    <div class="form-group">
                      <label>Search</label>
                      <input
                        class="form-control form-control-sm"
                        type="text"
                        placeholder="Search"
                        v-model="meta.search"
                        @keyup="search"
                      />
                    </div>
                  </div>
                  <div class="col-sm-3">
                    <div class="form-group">
                      <label for="exampleInputUsername1">Reference</label>
                      <select
                        class="form-control form-control-sm"
                        :class="{ 'is-invalid': errors.reference_type_id }"
                        v-model="meta.reference_type_id"
                      >
                        <option value="">Select Reference</option>
                        <option
                          v-for="(reference, key) in references"
                          :key="key"
                          :value="reference.reference_type_id"
                        >
                          {{ reference.reference_type }}
                        </option>
                      </select>
                      <span
                        v-if="errors.reference_type_id"
                        class="invalid-feedback"
                        >{{ errors.reference_type_id[0] }}</span
                      >
                    </div>
                  </div>

                  <div class="col-sm-3">
                    <div class="dropdown" @click="showDropdown">
                      <div class="overselect"></div>
                      <label for="exampleInputUsername2">Group</label>
                      <select class="form-control form-control-sm">
                        <option value="">Please Group</option>
                      </select>
                    </div>
                    <div class="multiselect" v-if="show">
                      <ul>
                        <li
                          class=""
                          v-for="(group, key) in customer_groups1"
                          :key="key"
                        >
                          <input
                            type="checkbox"
                            :value="group.group_id"
                            v-model="meta.groups"
                            style="padding: 2px"
                          />
                          <label style="margin-left: 5px">{{
                            group.group
                          }}</label>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div class="col-sm-2 mt-auto">
                    <div class="form-group">
                      <button
                        class="btn btn-sm btn-primary mr-2"
                        @click="search"
                      >
                        <i class="fa fa-search mr-1"></i>Search
                      </button>
                      <button
                        class="btn btn-sm btn-danger"
                        @click="resetSearch"
                      >
                        <i class="fa fa-times mr-1"></i>Reset
                      </button>
                    </div>
                  </div>
                </div>

                <div class="table-responsive">
                  <table
                    class="table table-bordered text-nowrap table-striped table-sm"
                  >
                    <thead>
                      <tr>
                        <th class="text-center">#</th>
                        <th @click="sort('customer_no')">
                          Customer No
                          <span>
                            <i
                              v-if="
                                meta.keyword == 'customer_no' &&
                                meta.order_by == 'asc'
                              "
                              class="fas fa-sort-up"
                            ></i>
                            <i
                              v-else-if="
                                meta.keyword == 'customer_no' &&
                                meta.order_by == 'desc'
                              "
                              class="fas fa-sort-down"
                            ></i>
                            <i v-else class="fas fa-sort"></i>
                          </span>
                        </th>
                        <th @click="sort('customer_name')">
                          Customer Name
                          <span>
                            <i
                              v-if="
                                meta.keyword == 'customer_name' &&
                                meta.order_by == 'asc'
                              "
                              class="fas fa-sort-up"
                            ></i>
                            <i
                              v-else-if="
                                meta.keyword == 'customer_name' &&
                                meta.order_by == 'desc'
                              "
                              class="fas fa-sort-down"
                            ></i>
                            <i v-else class="fas fa-sort"></i>
                          </span>
                        </th>
                        <th @click="sort('reference_type_id')">
                          Reference
                          <span>
                            <i
                              v-if="
                                meta.keyword == 'reference_type_id' &&
                                meta.order_by == 'asc'
                              "
                              class="fas fa-sort-up"
                            ></i>
                            <i
                              v-else-if="
                                meta.keyword == 'reference_type_id' &&
                                meta.order_by == 'desc'
                              "
                              class="fas fa-sort-down"
                            ></i>
                            <i v-else class="fas fa-sort"></i>
                          </span>
                        </th>
                        <th>Group</th>
                        <th @click="sort('kyc_status')" class="text-center">
                          KYC
                          <span>
                            <i
                              v-if="
                                meta.keyword == 'kyc_status' &&
                                meta.order_by == 'asc'
                              "
                              class="fas fa-sort-up"
                            ></i>
                            <i
                              v-else-if="
                                meta.keyword == 'kyc_status' &&
                                meta.order_by == 'desc'
                              "
                              class="fas fa-sort-down"
                            ></i>
                            <i v-else class="fas fa-sort"></i>
                          </span>
                        </th>
                        <th class="text-center" v-if="get_activity.length">
                          Status
                        </th>
                        <th class="text-center" v-if="get_activity.length">
                          Referral
                        </th>
                        <!-- <th class="text-center" v-if="get_activity.length">
													TSD Status
												</th> -->
                        <th class="text-center" v-if="get_activity.length">
                          Action
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="(customer, key) in customers" :key="key">
                        <td class="text-center">{{ meta.from + key }}</td>
                        <td>
                          <a href="#" @click.prevent="view(customer)">
                            {{ customer.customer_no }}
                          </a>
                        </td>
                        <td>
                          <a href="#" @click.prevent="view(customer)">
                            {{ customer.customer_name }}
                          </a>
                        </td>

                        <td>
                          <a href="#" @click.prevent="view(customer)">
                            {{ customer.reference_type?.reference_type }}
                          </a>
                        </td>
                        <td>
                          <span
                            class="badge badge-success mr-1"
                            v-for="(
                              customer_group, key
                            ) in customer.customer_groups"
                            :key="key"
                          >
                            {{ customer_group?.group?.group }}
                          </span>
                          <span
                            class="cursor badge badge-primary mr-1"
                            data-toggle="modal"
                            data-target="#add_groups"
                            @click="selectCustomer(customer)"
                          >
                            <i class="fa fa-plus"></i>
                          </span>
                        </td>
                        <td
                          v-if="customer.kyc_status == '0'"
                          class="text-center"
                        >
                          <span class="badge badge-warning">Pending</span>
                        </td>
                        <td v-else class="text-center">
                          <span class="badge badge-success">Approved</span>
                        </td>
                        <td class="text-center" v-if="get_activity.length">
                          <div class="card-tools" v-can="'customers.delete'">
                            <div
                              class="custom-control custom-switch custom-switch-on-success custom-switch-off-danger"
                            >
                              <input
                                type="checkbox"
                                class="custom-control-input"
                                :id="'admin' + customer.customer_id"
                                v-model="customer.admin_status"
                                @click="toggleAdminStatus(customer)"
                              />
                              <label
                                class="custom-control-label"
                                :for="'admin' + customer.customer_id"
                              ></label>
                            </div>
                          </div>
                        </td>
                        <td class="text-center" v-if="get_activity.length">
                          <div class="card-tools" v-can="'customers.referral'">
                            <div
                              class="custom-control custom-switch custom-switch-on-success custom-switch-off-danger"
                            >
                              <input
                                type="checkbox"
                                class="custom-control-input"
                                :id="customer.customer_id"
                                v-model="customer.referral_status"
                                @click="toggleStatus(customer)"
                              />
                              <label
                                class="custom-control-label"
                                :for="customer.customer_id"
                              ></label>
                            </div>
                          </div>
                        </td>

                        <!-- <td class="text-center" v-if="get_activity.length">
													<div class="card-tools">
														<div
															class="custom-control custom-switch custom-switch-on-success custom-switch-off-danger">
															<input type="checkbox" class="custom-control-input"
																:id="'customer' + customer.customer_id"
																v-model="customer.tds_status"
																@click="toggleTdsStatus(customer)" />
															<label class="custom-control-label"
																:for="'customer' + customer.customer_id"></label>
														</div>
													</div>
												</td> -->

                        <td class="text-center" v-if="get_activity.length">
                          <button
                            v-can="'customers.call'"
                            class="btn btn-xs btn-outline-secondary"
                            @click.prevent="callCustomer(customer.mobile_no)"
                          >
                            <i class="fa fa-phone"></i>
                          </button>

                          <a
                            v-can="'customers.message/mail'"
                            class="btn btn-xs btn-outline-primary"
                            :href="'mailto:' + customer.email"
                          >
                            <i class="fa fa-envelope"></i>
                          </a>
                        </td>
                      </tr>
                      <tr v-if="customers.length <= 0">
                        <td colspan="20" style="text-align: center">
                          No Records Found
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              <div class="card-footer">
                <div class="float-right">
                  <Pagination
                    :maxPage="meta.maxPage"
                    :totalPages="meta.lastPage"
                    :currentPage="meta.page"
                    @pagechanged="onPageChange"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <!-- <div class="modal fade" id="call_confirmation" ref="closeModal">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Call Confirmation</h5>
            <button
              type="button"
              id="close"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <div class="row">
              <div class="col-sm-12">
                <div class="form-group">
                  <label for="sender_id"
                    >Are you sure you want to make a call to
                    {{ customer?.customer_name }}?
                  </label>
                </div>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button
              class="btn btn-success"
              data-toggle="modal"
              data-target="#call_details"
              @click="callCustomer(customer.mobile_no)"
            >
              <i class="fas fa-phone"></i> Call
            </button>
            <button
              type="button"
              class="btn btn-danger"
              @click="discard"
              data-dismiss="modal"
              ref="closeMessage"
            >
              <i class="fa fa-times"></i>
              Close
            </button>
          </div>
        </div>
      </div>
    </div> -->

    <!-- <div class="modal fade" id="call_details" v-if="(this.flag = true)">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Call Details</h5>
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <div class="row">
              <div class="col-sm-12">
                <div class="form-group">
                  <label for="sender_id">Note </label>
                  <textarea
                    rows="3"
                    type="text"
                    placeholder="Enter note"
                    class="form-control"
                    v-model="customer.note"
                  ></textarea>
                </div>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button
              class="btn btn-success"
              data-toggle="modal"
              data-target="#call_details"
              @click="callDetails(customer)"
            >
              <i class="fas fa-save"></i> submit
            </button>
            <button
              type="button"
              class="btn btn-danger"
              @click="discard"
              data-dismiss="modal"
              ref="closeMessage"
            >
              <i class="fa fa-times"></i>
              Close
            </button>
          </div>
        </div>
      </div>
    </div> -->

    <div class="modal fade" id="add_groups">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Assign Groups</h5>
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <div class="row">
              <div class="col-sm-4" v-for="(group, key) in groups" :key="key">
                <div class="form-check">
                  <input
                    class="form-check-input"
                    type="checkbox"
                    :id="group.group_id"
                    :value="group.group_id"
                    v-model="customer_groups"
                  />
                  <label class="form-check-label" :for="group.group_id">
                    {{ group.group }}
                  </label>
                </div>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-danger"
              data-dismiss="modal"
              ref="CloseGroup"
            >
              <i class="fa fa-times"></i> Close
            </button>
            <button type="button" class="btn btn-success" @click="addGroup()">
              <i class="fas fa-save"></i> Submit
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Pagination from "@/components/Pagination.vue";
export default {
  name: "Customer.Index",
  components: { Pagination },
  data() {
    return {
      status: true,
      customer_id: "",
      customer: {
        note: "",
      },
      flag: false,

      meta: {
        search: "",
        order_by: "asc",
        keyword: "customer_id",
        per_page: 50,
        totalRows: 0,
        page: 1,
        lastPage: 1,
        from: 1,
        maxPage: 1,
        trashed: false,
        reference_type_id: "",
        groups: [],
      },
      customers: [],
      customer_groups: [],
      groups: [],
      references: [],
      customer_groups1: [],
      show: false,
      errors: [],
      get_activity: [],
    };
  },

  beforeRouteEnter(to, from, next) {
    next((vm) => {
      if (from.name == "Customers.View" && vm.$store.getters.meta) {
        vm.meta = vm.$store.getters.meta;
      } else {
        vm.$store.dispatch("setMeta", {});
      }
      vm.get_activity = vm.$store.getters.permissions.filter(function (
        element
      ) {
        return (
          element.ability.ability.includes("customers.message/mail	") ||
          element.ability.ability.includes("customers.delete") ||
          element.ability.ability.includes("customers.call") ||
          element.ability.ability.includes("customers.referral")
        );
      });
      vm.index();
      vm.getGroups();
      vm.getReferenceType();
    });
  },

  methods: {
    submitForm() {
      let vm = this;
      if (vm.status) {
        vm.create();
      } else {
        vm.update();
      }
    },

    search() {
      let vm = this;
      vm.show = false;
      vm.meta.page = 1;
      vm.index();
    },
    sort(field) {
      this.meta.keyword = field;
      this.meta.order_by = this.meta.order_by == "asc" ? "desc" : "asc";
      this.index();
    },
    getGroups() {
      let vm = this;
      if (this.$store.getters.groups.length) {
        vm.groups = vm.$store.getters.groups;
      } else {
        vm.$store
          .dispatch("post", { uri: "getGroups" })
          .then(function (response) {
            vm.groups = response.data.data;
            vm.$store.dispatch("setGroups", vm.groups);
          })
          .catch(function (error) {
            vm.errors = error.response.data.errors;
            vm.$store.dispatch("error", error.response.data.message);
          });
      }
    },

    toggleStatus(customer) {
      let vm = this;
      customer.referral_status = !customer.referral_status;
      let loader = vm.$loading.show();
      let uri = { uri: "referralStatus", data: customer };
      vm.$store
        .dispatch("post", uri)
        .then(function () {
          loader.hide();
          vm.$store.dispatch(
            "success",
            "Referral Status is successfully updated"
          );
        })
        .catch(function (error) {
          loader.hide();
          vm.errors = error.response.data.errors;
          vm.$store.dispatch("error", error.response.data.message);
        });
    },

    toggleAdminStatus(customer) {
      let vm = this;
      customer.admin_status = !customer.admin_status;
      let loader = vm.$loading.show();
      let uri = { uri: "changeAdminStatus", data: customer };
      vm.$store
        .dispatch("post", uri)
        .then(function () {
          loader.hide();
          vm.$store.dispatch(
            "success",
            "Customer Status is successfully updated"
          );
        })
        .catch(function (error) {
          loader.hide();
          vm.errors = error.response.data.errors;
          vm.$store.dispatch("error", error.response.data.message);
        });
    },

    toggleTdsStatus(customer) {
      let vm = this;
      let loader = vm.$loading.show();
      let uri = { uri: "changeTdsStatus", data: customer };
      vm.$store
        .dispatch("post", uri)
        .then(function () {
          loader.hide();
          vm.$store.dispatch("success", "TDS Status is successfully updated");
        })
        .catch(function (error) {
          loader.hide();
          vm.errors = error.response.data.errors;
          vm.$store.dispatch("error", error.response.data.message);
        });
    },

    view(customer) {
      let vm = this;
      this.$store.dispatch("setMeta", vm.meta);
      this.$router.push("/customers/" + customer.customer_id + "/view");
    },

    index() {
      let vm = this;
      vm.$store
        .dispatch("post", { uri: "paginateCustomers", data: vm.meta })
        .then(function (response) {
          vm.customers = response.data.data;
          vm.meta.totalRows = response.data.meta.total;
          vm.meta.lastPage = response.data.meta.last_page;
          vm.meta.from = response.data.meta.from;
          vm.meta.maxPage = vm.meta.lastPage >= 3 ? 3 : vm.meta.lastPage;
        })
        .catch(function (error) {
          vm.errors = error.response.data.errors;
          vm.$store.dispatch("error", error.response.data.message);
        });
    },

    isTrashed() {
      let vm = this;
      vm.meta.trashed = !vm.meta.trashed;
      if (vm.meta.trashed) vm.meta.page = 1;
      vm.index();
    },

    create() {
      let vm = this;
      let loader = vm.$loading.show();
      let uri = { uri: "addCustomer", data: vm.customer };
      vm.$store
        .dispatch("post", uri)
        .then(function () {
          loader.hide();
          vm.$store.dispatch("success", "Customer is successfully created");
          vm.currency = "";
          vm.index();
        })
        .catch(function (error) {
          loader.hide();
          vm.errors = error.response.data.errors;
          vm.$store.dispatch("error", error.response.data.message);
        });
    },

    discard() {
      let vm = this;
      vm.customer = "";
      vm.status = true;
      vm.index();
    },

    update() {
      let vm = this;
      let loader = vm.$loading.show();
      let uri = { uri: "updateCustomer", data: vm.customer };
      vm.$store
        .dispatch("post", uri)
        .then(function () {
          loader.hide();
          vm.$store.dispatch("success", "Customer is successfully updated");
          vm.customer = "";
          vm.status = true;
          vm.index();
        })
        .catch(function (error) {
          loader.hide();
          vm.errors = error.response.data.errors;
          vm.$store.dispatch("error", error.response.data.message);
        });
    },

    onPageChange(page) {
      this.meta.page = page;
      this.index();
    },

    selectCustomer(customer) {
      let vm = this;
      vm.customer_id = customer.customer_id;
      let selected_groups = customer.customer_groups;
      vm.customer_groups = [];
      selected_groups.forEach((selected_group) => {
        vm.customer_groups.push(selected_group.group_id);
      });
    },

    addGroup() {
      let vm = this;
      let loader = vm.$loading.show();
      let data = {
        customer_id: vm.customer_id,
        groups: vm.customer_groups,
      };
      let uri = { uri: "addCustomerGroup", data: data };
      vm.$store
        .dispatch("post", uri)
        .then(function () {
          loader.hide();
          vm.$store.dispatch(
            "success",
            "Customer Group is successfully updated"
          );
          vm.$refs.CloseGroup.click();
          vm.index();
        })
        .catch(function (error) {
          loader.hide();
          vm.errors = error.response.data.errors;
          vm.$store.dispatch("error", error.response.data.message);
        });
    },

    callCustomer(to) {
      let vm = this;
      let loader = vm.$loading.show();
      let data = {
        From: "0" + vm.$store.getters.user.mobile_no,
        To: "0" + to,
      };
      let uri = { uri: "customerCallConnect", data: data };
      vm.$store
        .dispatch("post", uri)
        .then(function () {
          loader.hide();
          vm.$store.dispatch(
            "success",
            "Call connected, please wait for sometime"
          );
        })
        .catch(function (error) {
          loader.hide();
          vm.errors = error.response.data.errors;
          vm.$store.dispatch("error", error.response.data.message);
        });
    },
    getReferenceType() {
      let vm = this;

      if (this.$store.getters.reference_types.length > 0) {
        vm.references = vm.$store.getters.reference_types;
        vm.getCustomerGroups();
      } else {
        vm.$store
          .dispatch("post", { uri: "getReferenceTypes" })
          .then(function (response) {
            vm.references = response.data.data;
            vm.$store.dispatch("setReferenceTypes", vm.references);
            vm.getCustomerGroups();
          })
          .catch(function (error) {
            vm.errors = error.response.data;
            vm.$store.dispatch("error", error.response.data.message);
          });
      }
    },
    callConfirmation(customer) {
      let vm = this;
      vm.customer = customer;
    },
    callDetails(customer) {
      let vm = this;
      let loader = vm.$loading.show();
      let data = {
        customer_id: customer.customer_id,
        user_id: vm.$store.getters.user.user_id,
        note: customer.note,
      };
      let uri = { uri: "addCall", data: data };
      vm.$store
        .dispatch("post", uri)
        .then(function () {
          loader.hide();
          vm.$store.dispatch("success", "Call detail is successfully created");
          vm.$refs.CloseGroup.click();
          vm.customer.note = "";
          vm.index();
        })
        .catch(function (error) {
          loader.hide();
          vm.errors = error.response.data.errors;
          vm.$store.dispatch("error", error.response.data.message);
        });
    },
    getCustomerGroups() {
      let vm = this;
      if (this.$store.getters.customer_groups.length > 0) {
        vm.customer_groups1 = vm.$store.getters.customer_groups;
      } else {
        vm.$store
          .dispatch("post", { uri: "getGroups" })
          .then(function (response) {
            vm.customer_groups1 = response.data.data;
            vm.$store.dispatch("setCustomerGroups", vm.customer_groups1);
          })
          .catch(function (error) {
            vm.errors = error.response.data;
            vm.$store.dispatch("error", error.response.data.message);
          });
      }
    },

    showDropdown() {
      this.show = !this.show;
    },
    resetSearch() {
      let vm = this;
      vm.show = false;
      vm.meta.reference_type_id = "";
      vm.meta.groups = [];
      vm.errors = [];
      vm.index();
    },
  },
};
</script>
<style scoped>
.dropdown {
  position: relative;
  cursor: pointer;
}

.multiselect {
  position: absolute;
  width: 250px;
}

.multiselect ul {
  border: 1px solid #ddd;
  border-top: 0;
  border-radius: 0 0 3px 3px;
  left: 0px;
  padding: 8px 8px;
  top: -0.1rem;
  width: 100%;
  list-style: none;
  max-height: 150px;
  overflow: auto;
  background: white;
}

.overselect {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}
</style>
