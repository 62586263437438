<template>
	<div class="content-wrapper">
		<nav aria-label="breadcrumb">
			<ol class="breadcrumb">
				<li class="breadcrumb-item">
					<a href="#">
						<i class="fa fa-home"></i>
						Dashboard
					</a>
				</li>
				<li class="breadcrumb-item active">Employee CheckIn Logs</li>
			</ol>
		</nav>
		<section class="content">
			<div class="container-fluid">
				<div class="row justify-content-md-center">
					<div class="col-sm-12">
						<div class="card">
							<div class="card-header">
								<h3 class="card-title">Employee CheckIn Logs</h3>
							</div>
							<div class="card-body">
								<div class="row">
									<div class="col-sm-4">
										<div class="form-group">
											<label for="from_date">From Date</label>
											<input class="form-control form-control-sm" type="date" placeholder="Search"
												v-model="meta.from_date" @change="changeFromDate($event)" />
										</div>
									</div>
									<div class="col-sm-4">
										<div class="form-group">
											<label for="from_date">To Date</label>
											<input class="form-control form-control-sm" type="date" placeholder="Search"
												v-model="meta.to_date" @change="changeToDate($event)" />
										</div>
									</div>
									<div class="col-sm-4">
										<div class="form-group">
											<label for="user_id">User </label>
											<select class="form-control form-control-sm"
												:class="{ 'is-invalid': errors.user }" v-model="meta.user_id"
												@change="index()">
												<option value="">Select User</option>
												<option v-for="(user, key) in users" :key="key" :value="user.user_id">{{
													user.name }}</option>
											</select>
											<span v-if="errors.user_id" class="invalid-feedback">{{ errors.user_id[0]
												}}</span>
										</div>
									</div>
								</div>
								<table class="table table-responsive-sm table-bordered table-striped table-sm">
									<thead>
										<tr>
											<th class="text-center">#</th>
											<th>Employee ID</th>
											<th>Employee Name</th>
											<th>Mobile No</th>
											<th>Check-In</th>
											<th>Check-Out</th>
											<th>No of Hours</th>
											<th>Security</th>
										</tr>
									</thead>
									<tbody>
										<tr v-for="(log, key) in logs" :key="key">
											<td class="text-center">{{ meta.from + key }}</td>
											<td>{{ log.user?.user_id }}</td>
											<td>{{ log.user?.name }}</td>
											<td>{{ $mask(log.user?.mobile_no) }}</td>
											<td>{{ $formatDate(log.start_date_time, 'india') }}</td>
											<td>{{ $formatDate(log.end_date_time, 'india') }}</td>
											<td>{{ calculateDifference(log.start_date_time, log.end_date_time) }}</td>
											<td>{{ log.start_user?.name }}</td>
										</tr>
										<tr v-if="logs.length <= 0">
											<td colspan="8" style="text-align: center;">No Records Found</td>
										</tr>
									</tbody>
								</table>
							</div>
							<div class="card-footer">
								<div class="float-right">
									<Pagination :maxPage="meta.maxPage" :totalPages="meta.lastPage"
										:currentPage="meta.page" @pagechanged="onPageChange" />
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</section>
	</div>
</template>

<script>
import moment from "moment";
import Pagination from "@/components/Pagination.vue";
export default {
	name: "Log.Index",
	components: { Pagination },
	data() {
		return {
			status: true,
			meta: {
				from_date: "",
				to_date: "",
				customer_id: "",
				customer_name: "",
				user_id: "",
				order_by: "desc",
				keyword: "start_date_time",
				per_page: 50,
				totalRows: 0,
				page: 1,
				lastPage: 1,
				from: 1,
				maxPage: 1,
				trashed: false,
				log_type: 'User',
				branch_id: this.$store.getters.user.branch_id
			},
			users: [],
			customers: [],
			logs: [],
			errors: [],
		};
	},
	mounted() {
		let vm = this;
		vm.index();
		vm.getUsers();
	},

	methods: {
		index() {
			let vm = this;
			vm.$store
				.dispatch("post", { uri: "paginateLogs", data: vm.meta })
				.then(function (response) {
					vm.logs = response.data.data;
					vm.meta.totalRows = response.data.meta.total;
					vm.meta.lastPage = response.data.meta.last_page;
					vm.meta.from = response.data.meta.from;
					vm.meta.maxPage = vm.meta.lastPage >= 3 ? 3 : vm.meta.lastPage;
				})
				.catch(function (error) {
					vm.errors = error.response.data.errors;
					vm.$store.dispatch("error", error.response.data.message);
				});
		},

		getUsers() {
			let vm = this;
			if (this.$store.getters.branch_users.length > 0) {
				vm.users = vm.$store.getters.branch_users
			}
			else {
				let uri = { uri: "getBranchUsers", data: vm.meta };
				vm.$store
					.dispatch("post", uri)
					.then(function (response) {
						vm.users = response.data.data;
						vm.$store.dispatch('setBranchUsers', vm.users);
					})
					.catch(function (error) {
						vm.errors = error.response.data.errors;
						vm.$store.dispatch("error", error.response.data.message);
					});
			}
		},

		changeFromDate(event) {
			let vm = this;
			vm.meta.from_date = event.target.value;
			vm.index();
		},

		changeToDate(event) {
			let vm = this;
			vm.meta.to_date = event.target.value;
			vm.index();
		},
		calculateDifference(from_date, to_date) {
			if (from_date == null || to_date == null || from_date == '' || to_date == '') {
				return null;
			}
			else {
				const start = moment(from_date);
				const end = moment(to_date);
				const duration = moment.duration(end.diff(start));
				return `${duration.hours()} hr ${duration.minutes()} min`;
			}
		},
		onPageChange(page) {
			this.meta.page = page;
			this.index();
		},
	},
};
</script>
