import { createStore } from "vuex";
import { createToast } from "mosha-vue-toastify";
import "mosha-vue-toastify/dist/style.css";
import axios from "axios";
// import {router} from '../main.js'

export default createStore({
	state: {
		apiUrl: process.env.VUE_APP_API_URL + "/api/",
		pdfUrl: process.env.VUE_APP_API_URL + "/",
		user: null,
		organization: null,
		roles: [],
		groups: [],
		reference_types: [],
		branch_users: [],
		payment_modes: [],
		categories: [],
		payment_terms: [],
		vendors: [],
		accounts: [],
		customer_groups: [],
		promotions: [],
		branch_tables: [],
		branch_denominations: [],
		session_denominations: [],
		log_users: [],
		token: "",
		permissions: [],
		meta: {},
	},

	mutations: {
		setUser(state, user) {
			state.user = user;
		},
		setOrganization(state, organization) {
			state.organization = organization;
		},
		setRoles(state, roles) {
			state.roles = roles;
		},
		setGroups(state, groups) {
			state.groups = groups;
		},
		setCustomerGroups(state, customer_groups) {
			state.customer_groups = customer_groups;
		},
		setReferenceTypes(state, reference_types) {
			state.reference_types = reference_types;
		},
		setBranchUsers(state, branch_users) {
			state.branch_users = branch_users;
		},
		setPaymentModes(state, payment_modes) {
			state.payment_modes = payment_modes;
		},
		setLogUsers(state, log_users) {
			state.log_users = log_users;
		},
		setPromotions(state, promotions) {
			state.promotions = promotions;
		},
		setBranchTables(state, branch_tables) {
			state.branch_tables = branch_tables;
		},
		setBranchDenominations(state, branch_denominations) {
			state.branch_denominations = branch_denominations;
		},
		setSessionDenominations(state, session_denominations) {
			state.session_denominations = session_denominations;
		},
		setCategories(state, categories) {
			state.categories = categories;
		},
		setPaymentTerms(state, payment_terms) {
			state.payment_terms = payment_terms;
		},
		setVendors(state, vendors) {
			state.vendors = vendors;
		},
		setAccounts(state, accounts) {
			state.accounts = accounts;
		},
		setToken(state, token) {
			state.token = token;
		},
		setUrl(state, url) {
			state.apiUrl = url;
		},
		setPermissions(state, permissions) {
			state.permissions = permissions;
		},
		setMeta(state, meta) {
			state.meta = meta;
		},
	},

	getters: {
		meta(state) {
			return state.meta;
		},
		user(state) {
			return state.user;
		},
		token(state) {
			return state.token;
		},
		organization(state) {
			return state.organization;
		},
		roles(state) {
			return state.roles;
		},
		groups(state) {
			return state.groups;
		},
		log_users(state) {
			return state.log_users;
		},
		customer_groups(state) {
			return state.customer_groups;
		},
		reference_types(state) {
			return state.reference_types;
		},
		branch_users(state) {
			return state.branch_users;
		},
		branch_denominations(state) {
			return state.branch_denominations;
		},
		session_denominations(state) {
			return state.session_denominations;
		},
		payment_modes(state) {
			return state.payment_modes;
		},
		promotions(state) {
			return state.promotions;
		},
		branch_tables(state) {
			return state.branch_tables;
		},
		categories(state) {
			return state.categories;
		},
		payment_terms(state) {
			return state.payment_terms;
		},
		vendors(state) {
			return state.vendors;
		},
		accounts(state) {
			return state.accounts;
		},
		apiUrl(state) {
			return state.apiUrl;
		},
		pdfUrl(state) {
			return state.pdfUrl;
		},
		permissions(state) {
			return state.permissions;
		},
	},

	actions: {
		async setMeta(context, payload) {
			await context.commit("setMeta", payload);
		},

		async setUser(context, payload) {
			sessionStorage.setItem("user", JSON.stringify(payload));
			await context.commit("setUser", payload);
		},
		async setToken(context, payload) {
			sessionStorage.setItem("token", payload);
			await context.commit("setToken", payload);
		},
		async setPermissions(context, payload) {
			sessionStorage.setItem("permissions", JSON.stringify(payload));
			await context.commit("setPermissions", payload);
		},
		async setOrganization(context, payload) {
			await context.commit("setOrganization", payload);
		},
		async setRoles(context, payload) {
			await context.commit("setRoles", payload);
		},
		async setReferenceTypes(context, payload) {
			await context.commit("setReferenceTypes", payload);
		},
		async setGroups(context, payload) {
			await context.commit("setGroups", payload);
		},
		async setLogUsers(context, payload) {
			await context.commit("setLogUsers", payload);
		},
		async setCustomerGroups(context, payload) {
			await context.commit("setCustomerGroups", payload);
		},
		async setBranchDenominations(context, payload) {
			await context.commit("setBranchDenominations", payload);
		},
		async setSessionDenominations(context, payload) {
			await context.commit("setSessionDenominations", payload);
		},
		async setBranchUsers(context, payload) {
			await context.commit("setBranchUsers", payload);
		},
		async setPaymentModes(context, payload) {
			await context.commit("setPaymentModes", payload);
		},
		async setBranchTables(context, payload) {
			await context.commit("setBranchTables", payload);
		},
		async setPromotions(context, payload) {
			await context.commit("setPromotions", payload);
		},
		async setCategories(context, payload) {
			await context.commit("setCategories", payload);
		},
		async setPaymentTerms(context, payload) {
			await context.commit("setPaymentTerms", payload);
		},
		async setVendors(context, payload) {
			await context.commit("setVendors", payload);
		},
		async setAccounts(context, payload) {
			await context.commit("setAccounts", payload);
		},
		async logout(context) {
			sessionStorage.removeItem("user");
			sessionStorage.removeItem("token");
			sessionStorage.removeItem("permissions");
			await context.commit("setUser", null);
			await context.commit("setToken", "");
			await context.commit("setPermissions", []);
		},

		auth(context, payload) {
			return new Promise((resolve, reject) => {
				axios
					.post(this.state.apiUrl + payload.uri, payload.data)
					.then(function (response) {
						resolve(response);
					})
					.catch(function (error) {
						reject(error);
					});
			});
		},

		get(context, payload) {
			return new Promise((resolve, reject) => {
				axios
					.get(this.state.apiUrl + payload.uri, payload.data, {
						headers: {
							Authorization: "Bearer" + " " + context.getters.token,
						},
					})
					.then(function (response) {
						resolve(response);
					})
					.catch(function (error) {
						reject(error);
					});
			});
		},

		post(context, payload) {
			return new Promise((resolve, reject) => {
				axios
					.post(this.state.apiUrl + payload.uri, payload.data, {
						headers: {
							Authorization: "Bearer" + " " + context.getters.token,
						},
					})
					.then(function (response) {
						resolve(response);
					})
					.catch(function (error) {
						reject(error);
						if (error.response.data.message == "Unauthenticated.") {
							context.dispatch("logout");
							window.location.href = "/#/login";
							window.location.reload();
						}
					});
			});
		},

		patch(context, payload) {
			return new Promise((resolve, reject) => {
				axios
					.patch(this.state.apiUrl + payload.uri, payload.data, {
						headers: {
							Authorization: "Bearer" + " " + context.getters.token,
						},
					})
					.then(function (response) {
						resolve(response);
					})
					.catch(function (error) {
						reject(error);
					});
			});
		},

		delete(context, payload) {
			return new Promise((resolve, reject) => {
				axios
					.delete(this.state.apiUrl + payload.uri)
					.then(function (response) {
						resolve(response);
					})
					.catch(function (error) {
						reject(error);
					});
			});
		},

		upload(context, payload) {
			return new Promise((resolve, reject) => {
				axios
					.post(this.state.apiUrl + payload.uri, payload.data, {
						headers: {
							Authorization: "Bearer" + " " + context.getters.token,
							"Content-Type": "multipart/form-data",
						},
					})
					.then(function (response) {
						resolve(response);
					})
					.catch(function (error) {
						reject(error);
					});
			});
		},

		async error(context, description) {
			await createToast(
				{
					title: "Error",
					description: description || "The given data was invalid.",
				},
				{
					showIcon: true,
					hideProgressBar: true,
					type: "danger",
					position: "top-right",
				}
			);
		},

		async success(context, description) {
			await createToast(
				{
					title: "Success",
					description: description || "Data is successfuly subbmited.",
				},
				{
					showIcon: true,
					hideProgressBar: true,
					type: "success",
					position: "top-right",
				}
			);
		},
	},
});
