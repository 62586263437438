<template>
  <div class="content-wrapper">
    <nav aria-label="breadcrumb">
      <ol class="breadcrumb">
        <li class="breadcrumb-item">
          <a href="#">
            <i class="fa fa-home"></i>
            Dashboard
          </a>
        </li>
        <li class="breadcrumb-item active">Transactions</li>
        <div class="ml-auto">
          <button
            v-can="'transactions.bulk_download_reciept'"
            @click="downloadTransaction()"
            class="btn btn-sm btn-outline-primary"
          >
            <i class="fa fa-book"></i> Download Receipt
          </button>

          <!-- <a v-can="'transactions.bulk_download'" target="_blank"
                        :href="$store.getters.apiUrl + 'downloadTransactionDetails?from_date=' + meta.from_date + '&to_date=' + meta.to_date + '&transaction_type=' + meta.transaction_type + '&status=' + meta.status + '&payment_mode_id=' + meta.payment_mode_id + '&user_id=' + meta.user_id + '&customer_id=' + meta.customer_id"
                        class="btn btn-sm btn-outline-success">
                        <i class="fa fa-download"></i> Download Transactions
                    </a> -->
          <a
            v-can="'transactions.bulk_download'"
            :href="downloadUrl"
            @click.prevent="validateDateRange"
            class="btn btn-sm btn-outline-success"
          >
            <i class="fa fa-download"></i> Download Transactions
          </a>
        </div>
      </ol>
    </nav>
    <section class="content">
      <div class="container-fluid">
        <div class="row justify-content-md-center">
          <div class="col-sm-12">
            <div class="card">
              <div class="card-header">
                <h3 class="card-title">Transactions</h3>
                <div class="card-tools">
                  <label class="mr-2">Total : {{ total }}</label>
                </div>
              </div>
              <div class="card-body">
                <div class="row">
                  <div class="col-sm-2 g-2">
                    <div class="form-group">
                      <label for="from_date">From Date</label>
                      <input
                        class="form-control form-control-sm"
                        type="date"
                        placeholder="Search"
                        v-model="meta.from_date"
                      />
                    </div>
                  </div>
                  <div class="col-sm-2 g-2">
                    <div class="form-group">
                      <label for="from_date">To Date</label>
                      <input
                        class="form-control form-control-sm"
                        type="date"
                        placeholder="Search"
                        v-model="meta.to_date"
                      />
                    </div>
                  </div>
                  <div class="col-sm-3 g-2">
                    <div class="form-group">
                      <label for="from_date">Transaction Type</label>
                      <select
                        class="form-control form-control-sm"
                        v-model="meta.transaction_type"
                      >
                        <option value="">Select Transaction Type</option>
                        <option value="Deposit">Deposit</option>
                        <option value="Withdraw">Withdraw</option>
                        <option value="TDS">TDS</option>
                        <option value="BuyIn">BuyIn</option>
                        <option value="CashOut">CashOut</option>
                      </select>
                    </div>
                  </div>
                  <div class="col-sm-3 g-2">
                    <div class="form-group">
                      <label for="customer_id">Customer </label>
                      <Customer
                        :class="{ 'is-invalid': errors.customer_id }"
                        :customClass="{ 'is-invalid': errors.customer_id }"
                        :initialize="meta.customer_name"
                        @selectCustomer="selectCustomer($event)"
                        ref="customer_id"
                      ></Customer>
                      <span
                        v-if="errors.customer_id"
                        class="invalid-feedback"
                        >{{ errors.customer_id[0] }}</span
                      >
                    </div>
                  </div>

                  <div class="col-sm-2">
                    <div class="form-group">
                      <label for="from_date">Status</label>
                      <select
                        class="form-control form-control-sm"
                        v-model="meta.status"
                      >
                        <option value="">Select Status</option>
                        <option value="Approved">Approved</option>
                        <option value="Pending">Pending</option>
                        <option value="Rejected">Rejected</option>
                      </select>
                    </div>
                  </div>
                  <div class="col-sm-3">
                    <div class="form-group">
                      <label for="user_id">User </label>
                      <select
                        class="form-control form-control-sm"
                        :class="{ 'is-invalid': errors.user }"
                        v-model="meta.user_id"
                      >
                        <option value="">Select User</option>
                        <option
                          v-for="(user, key) in users"
                          :key="key"
                          :value="user.user_id"
                        >
                          {{ user.name }}
                        </option>
                      </select>
                      <span v-if="errors.user_id" class="invalid-feedback">{{
                        errors.user_id[0]
                      }}</span>
                    </div>
                  </div>
                  <div class="col-sm-3">
                    <div class="form-group">
                      <label for="payment_mode_id">Payment Mode </label>
                      <select
                        class="form-control form-control-sm"
                        :class="{ 'is-invalid': errors.payment_mode }"
                        v-model="meta.payment_mode_id"
                      >
                        <option value="">Select Payment Mode</option>
                        <option
                          v-for="(payment_mode, key) in payment_modes"
                          :key="key"
                          :value="payment_mode.payment_mode_id"
                        >
                          {{ payment_mode.payment_mode }}
                        </option>
                      </select>
                      <span
                        v-if="errors.payment_mode_id"
                        class="invalid-feedback"
                        >{{ errors.payment_mode_id[0] }}</span
                      >
                    </div>
                  </div>
                  <div class="col-sm-3" v-can="'global_dashboard.view'">
                    <div class="form-group">
                      <label for="payment_mode_id">Branch </label>
                      <select
                        class="form-control form-control-sm"
                        :class="{ 'is-invalid': errors.branch_id }"
                        v-model="meta.branch_id"
                      >
                        <option value="">Select Branch</option>
                        <option
                          v-for="(branch, key) in branches"
                          :key="key"
                          :value="branch.branch_id"
                        >
                          {{ branch.branch_name }}
                        </option>
                      </select>
                      <span
                        v-if="errors.branch_id"
                        class="invalid-feedback"
                        >{{ errors.branch_id[0] }}</span
                      >
                    </div>
                  </div>
                  <div class="col-sm-3 pt-4">
                    <button class="btn btn-sm btn-primary mr-2" @click="search">
                      <i class="fa fa-search mr-1"></i>Search
                    </button>
                    <button class="btn btn-sm btn-danger" @click="resetSearch">
                      <i class="fa fa-times mr-1"></i>Reset
                    </button>
                  </div>
                </div>

                <div class="table-responsive" style="height: 340px">
                  <table
                    class="table text-nowrap table-bordered table-striped table-sm table-head-fixed"
                  >
                    <thead>
                      <tr>
                        <th class="text-center">#</th>
                        <th @click="sort('transaction_no')">
                          T. No
                          <span>
                            <i
                              v-if="
                                meta.keyword == 'transaction_no' &&
                                meta.order_by == 'asc'
                              "
                              class="fas fa-sort-up"
                            ></i>
                            <i
                              v-else-if="
                                meta.keyword == 'transaction_no' &&
                                meta.order_by == 'desc'
                              "
                              class="fas fa-sort-down"
                            ></i>
                            <i v-else class="fas fa-sort"></i>
                          </span>
                        </th>
                        <th @click="sort('transaction_date_time')">
                          T. Date-Time
                          <span>
                            <i
                              v-if="
                                meta.keyword == 'transaction_date_time' &&
                                meta.order_by == 'asc'
                              "
                              class="fas fa-sort-up"
                            ></i>
                            <i
                              v-else-if="
                                meta.keyword == 'transaction_date_time' &&
                                meta.order_by == 'desc'
                              "
                              class="fas fa-sort-down"
                            ></i>
                            <i v-else class="fas fa-sort"></i>
                          </span>
                        </th>
                        <th @click="sort('transaction_type')">
                          T. Type
                          <span>
                            <i
                              v-if="
                                meta.keyword == 'transaction_type' &&
                                meta.order_by == 'asc'
                              "
                              class="fas fa-sort-up"
                            ></i>
                            <i
                              v-else-if="
                                meta.keyword == 'transaction_type' &&
                                meta.order_by == 'desc'
                              "
                              class="fas fa-sort-down"
                            ></i>
                            <i v-else class="fas fa-sort"></i>
                          </span>
                        </th>
                        <th>Customer</th>
                        <th>User</th>
                        <th>Mode</th>
                        <th class="text-right">Amount</th>
                        <th class="text-center">Ref. No</th>
                        <th class="text-center">Status</th>
                        <th class="text-center">Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="(transaction, key) in transactions" :key="key">
                        <td class="text-center">{{ meta.from + key }}</td>
                        <td>
                          <a
                            href="#"
                            @click.prevent="view_transaction(transaction)"
                          >
                            {{ transaction.transaction_no }}
                          </a>
                        </td>
                        <td>
                          {{
                            $formatDate(
                              transaction.transaction_date_time,
                              "india"
                            )
                          }}
                        </td>
                        <td>{{ transaction.transaction_type }}</td>
                        <td>{{ transaction?.customer?.customer_name }}</td>
                        <td>{{ transaction?.user?.name }}</td>
                        <td>{{ transaction?.payment_mode?.payment_mode }}</td>
                        <td class="text-right">
                          {{ formatCurrency(transaction.amount) }}
                        </td>
                        <!-- <td class="text-right" v-if="transaction.promotion_value!=0">
                                                    {{ transaction.promotion_value }}
                                                </td>
                                                <td class="text-center" v-else></td> -->
                        <td class="text-center">
                          {{ transaction.reference_id }}<br />
                          <div v-can="'transactions.verify'">
                            <div
                              v-if="
                                transaction?.payment_mode?.payment_mode ==
                                'Online'
                              "
                            >
                              <label
                                class="bank_status"
                                :for="
                                  'bank_status' + transaction.transaction_id
                                "
                                >B</label
                              >
                              <input
                                :disabled="transaction.bank_status"
                                type="checkbox"
                                :id="'bank_status' + transaction.transaction_id"
                                v-model="transaction.bank_status"
                                @click="
                                  updateBankStatus(transaction.transaction_id)
                                "
                              />
                            </div>
                            <div
                              class="row"
                              v-if="
                                transaction?.payment_mode?.payment_mode ==
                                'POS-Mswipe'
                              "
                            >
                              <div class="col-sm-6">
                                <label
                                  class="bank_status"
                                  :for="
                                    'pos_status' + transaction.transaction_id
                                  "
                                  >P</label
                                >
                                <input
                                  :disabled="transaction.pos_status"
                                  type="checkbox"
                                  :id="
                                    'pos_status' + transaction.transaction_id
                                  "
                                  v-model="transaction.pos_status"
                                  @click="
                                    updatePosStatus(transaction.transaction_id)
                                  "
                                />
                              </div>
                              <div class="col-sm-6">
                                <label
                                  class="bank_status"
                                  :for="
                                    'bank_status' + transaction.transaction_id
                                  "
                                  >B</label
                                >
                                <input
                                  :disabled="transaction.bank_status"
                                  type="checkbox"
                                  :id="
                                    'bank_status' + transaction.transaction_id
                                  "
                                  v-model="transaction.bank_status"
                                  @click="
                                    updateBankStatus(transaction.transaction_id)
                                  "
                                />
                              </div>
                            </div>
                          </div>
                        </td>
                        <td class="text-center">
                          <span
                            v-if="transaction.status == 'Pending'"
                            class="badge badge-warning"
                          >
                            Pending
                          </span>
                          <span
                            v-if="transaction.status == 'Approved'"
                            class="badge badge-success"
                          >
                            Approved
                          </span>
                          <span
                            v-if="transaction.status == 'Rejected'"
                            class="badge badge-danger"
                          >
                            Rejected
                          </span>
                        </td>
                        <td
                          class="text-center"
                          v-if="transaction.status == 'Approved'"
                        >
                          <a
                            v-can="'transactions.download'"
                            target="_blank"
                            :href="
                              $store.getters.pdfUrl +
                              'api/downloadTransaction?transaction_id=' +
                              transaction.transaction_id
                            "
                            class="btn btn-xs btn-outline-primary"
                            title="Download"
                          >
                            <i class="fas fa-solid fa-download"></i>
                          </a>
                        </td>
                        <td v-else class="text-center">-</td>
                      </tr>
                      <tr v-if="transactions.length <= 0">
                        <td colspan="11" class="text-center">
                          No Records Found
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              <div class="card-footer">
                <div class="float-right">
                  <Pagination
                    :maxPage="meta.maxPage"
                    :totalPages="meta.lastPage"
                    :currentPage="meta.page"
                    @pagechanged="onPageChange"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import Pagination from "@/components/Pagination.vue";
let Customer = require("../../components/Customer.vue").default;
export default {
  name: "User.Index",
  components: { Pagination, Customer },
  data() {
    return {
      status: true,
      meta: {
        from_date: "",
        to_date: "",
        transaction_type: "",
        status: "",
        customer_id: "",
        customer_name: "",
        user_id: "",
        payment_mode_id: "",
        order_by: "desc",
        keyword: "transaction_id",
        per_page: 50,
        totalRows: 0,
        page: 1,
        lastPage: 1,
        from: 1,
        maxPage: 1,
        trashed: false,
        branch_id: this.$store.getters.user.branch_id,
      },
      total: 0,
      users: [],
      customers: [],
      transactions: [],
      payment_modes: [],
      errors: [],
      branches:[]
    };
  },

  beforeRouteEnter(to, from, next) {
    next((vm) => {
      if (from.name == "Transactions.View" && vm.$store.getters.meta) {
        vm.meta = vm.$store.getters.meta;
      } else {
        vm.$store.dispatch("setMeta", {});
      }
      vm.index();
      vm.getUsers();
      vm.getBranches();
    });
  },

  computed: {
    downloadUrl() {
      const {
        from_date,
        to_date,
        transaction_type,
        status,
        payment_mode_id,
        user_id,
        customer_id,
      } = this.meta;
      return `${this.$store.getters.apiUrl}downloadTransactionDetails?from_date=${from_date}&to_date=${to_date}&transaction_type=${transaction_type}&status=${status}&payment_mode_id=${payment_mode_id}&user_id=${user_id}&customer_id=${customer_id}`;
    },
  },

  methods: {
    formatCurrency(amount) {
      // Convert number to Indian standard currency format
      const formattedAmount = new Intl.NumberFormat("en-IN", {
        style: "currency",
        currency: "INR",
      }).format(amount);

      return formattedAmount;
    },
    index() {
      let vm = this;
      vm.$store
        .dispatch("post", { uri: "paginateTransactions", data: vm.meta })
        .then(function (response) {
          vm.transactions = response.data.data;
          vm.meta.totalRows = response.data.meta.total;
          vm.meta.lastPage = response.data.meta.last_page;
          vm.meta.from = response.data.meta.from;
          vm.meta.maxPage = vm.meta.lastPage >= 3 ? 3 : vm.meta.lastPage;
          vm.getSumOfTransactions();
        })
        .catch(function (error) {
          vm.errors = error.response.data.errors;
          vm.$store.dispatch("error", error.response.data.message);
        });
    },

    getSumOfTransactions() {
      let vm = this;
      let uri = { uri: "transactions/getSumOfTransactions", data: vm.meta };
      vm.$store
        .dispatch("post", uri)
        .then(function (response) {
          vm.total = response.data;
        })
        .catch(function (error) {
          vm.errors = error.response.data.errors;
          vm.$store.dispatch("error", error.response.data.message);
        });
    },

    view_transaction(transaction) {
      let vm = this;
      this.$store.dispatch("setMeta", vm.meta);
      this.$router.push(
        "/transactions/" + transaction.transaction_id + "/view"
      );
    },

    getUsers() {
      let vm = this;
      if (vm.$store.getters.branch_users.length > 0) {
        vm.users = vm.$store.getters.branch_users;
        vm.getPaymentModes();
      } else {
        let uri = { uri: "getBranchUsers", data: vm.meta };
        vm.$store
          .dispatch("post", uri)
          .then(function (response) {
            vm.users = response.data.data;
            vm.$store.dispatch("setBranchUsers", vm.users);
            vm.getPaymentModes();
          })
          .catch(function (error) {
            vm.errors = error.response.data.errors;
            vm.$store.dispatch("error", error.response.data.message);
          });
      }
    },
    getPaymentModes() {
      let vm = this;
      if (this.$store.getters.payment_modes.length) {
        vm.payment_modes = vm.$store.getters.payment_modes;
      } else {
        let uri = { uri: "paymentModes" };
        vm.$store
          .dispatch("post", uri)
          .then(function (response) {
            vm.payment_modes = response.data.data;
            vm.$store.dispatch("setPaymentModes", vm.payment_modes);
          })
          .catch(function (error) {
            vm.errors = error.response.data.errors;
            vm.$store.dispatch("error", error.response.data.message);
          });
      }
    },
    getBranches() {
        let vm = this;
        vm.$store.dispatch("post", { uri: "getBranches" })
        .then(function (response) {
            vm.branches = response.data.data;
        })
        .catch(function (error) {
            vm.errors = error.response.data.errors;
            vm.$store.dispatch("error", error.response.data.message);
        });
    },

    updateBankStatus(transaction_id) {
      let vm = this;
      let data = {
        transaction_id: transaction_id,
      };
      let uri = { uri: "updateTransactionBankStatus", data: data };
      vm.$store.dispatch("post", uri);
    },

    updatePosStatus(transaction_id) {
      let vm = this;
      let data = {
        transaction_id: transaction_id,
      };
      let uri = { uri: "updateTransactionPosStatus", data: data };
      vm.$store.dispatch("post", uri);
    },

    changeTransactionType(event) {
      let vm = this;
      console.log("11", vm.meta.transaction_type);
      console.log("22", event.target.value);

      vm.meta.transaction_type = event.target.value;

      vm.index();
    },

    changeStatus(event) {
      let vm = this;
      vm.meta.status = event.target.value;
      vm.index();
    },

    changeFromDate(event) {
      let vm = this;
      vm.meta.from_date = event.target.value;
      vm.index();
    },

    changeToDate(event) {
      let vm = this;
      vm.meta.to_date = event.target.value;
      vm.index();
    },

    changeCustomer() {
      let vm = this;
      vm.customers.filter((customer) => {
        customer.customer_id == vm.meta.customer_id;
        vm.index();
      });
    },

    changeUser() {
      let vm = this;
      console.log("1", vm.meta.user_id);
      vm.users.filter((user) => {
        user.user_id == vm.meta.user_id;
        vm.index();
      });
    },
    changePaymentMode() {
      let vm = this;
      vm.payment_modes.filter((payment_mode) => {
        payment_mode.payment_mode_id == vm.meta.payment_mode_id;
        vm.index();
      });
    },

    onPageChange(page) {
      this.meta.page = page;
      this.index();
    },

    selectCustomer(e) {
      let vm = this;
      const customer = e.target.value;
      vm.meta.customer_id = customer.customer_id;
      vm.meta.customer_name = customer.customer_name;
      // vm.index();
    },

    downloadTransaction() {
      let vm = this;
      vm.$store
        .dispatch("post", { uri: "downloadTransactions", data: vm.meta })
        .then(function (response) {
          vm.$store.dispatch("success", response.data.message);
        })
        .catch(function (error) {
          vm.errors = error.response.data.errors;
          vm.$store.dispatch("error", error.response.data.message);
        });
    },
    search() {
      let vm = this;
      vm.meta.page = 1;
      vm.index();
    },
    resetSearch() {
      let vm = this;
      vm.meta.from_date = "";
      vm.meta.to_date = "";
      vm.meta.transaction_type = "";
      vm.meta.customer_name = "";
      vm.meta.customer_id = "";
      vm.meta.status = "";
      vm.meta.user_id = "";
      vm.meta.payment_mode_id = "";
      vm.errors = [];
      vm.index();
    },
    sort(field) {
      this.meta.keyword = field;
      this.meta.order_by = this.meta.order_by == "asc" ? "desc" : "asc";
      this.index();
    },

    validateDateRange() {
      const { from_date, to_date, transaction_type, status } = this.meta;
      if (!from_date || !to_date) {
        alert(
          "Please select 'From Date,' 'To Date,' 'Transaction Type,' and 'Status.'"
        );
        return;
      }

      const fromDate = new Date(from_date);
      const toDate = new Date(to_date);
      const dayDifference = (toDate - fromDate) / (1000 * 60 * 60 * 24);

      if (dayDifference < 0) {
        alert('"To Date" cannot be earlier than "From Date".');
        return;
      }

      if (dayDifference > 31) {
        alert("The date range cannot exceed 31 days.");
        return;
      }

      if (!transaction_type) {
        alert("Please select a valid Transaction Type.");
        return;
      }

      if (!status) {
        alert("Please select a valid Status.");
        return;
      }
      window.location.href = this.downloadUrl;
    },
  },
};
</script>

<style>
.bank_status {
  padding: 0px !important;
  display: block;
  margin-top: 5px !important;
  margin-bottom: 0px !important;
  line-height: 10px !important;
}
</style>
