<template>
	<aside class="main-sidebar sidebar-dark-primary elevation-4">
		<router-link to="/dashboard" class="brand-link router-link-exact-active">
			<img src="@/assets/logo.png" alt="Logo" class="brand-image" />

			<span class="brand-text font-weight-light">Golden Aces</span>
		</router-link>

		<div class="sidebar">
			<div class="user-panel mt-3 pb-1 d-flex">
				<div class="image">
					<img :src="$store.getters.user.avatar" class="img-circle elevation-2" alt="User Image" />
				</div>

				<div class="info" style="margin-top: -10px">
					<a class="d-block text-light">{{ $store.getters.user.name }}</a>
					<span class="text-light">
						<span>{{ $store?.getters?.user?.role?.role }}</span>
					</span>
				</div>
			</div>

			<nav class="mt-2">
				<ul class="nav nav-pills nav-sidebar flex-column" data-widget="treeview" role="menu"
					data-accordion="false">
					<li class="nav-item" v-can="'sessions.view'">
						<router-link to="/dashboard" class="nav-link">
							<i class="nav-icon fas fa-home blue"></i>
							<p>Dashboard</p>
						</router-link>
					</li>
					<li class="nav-item" v-if="permission('configuration')">
						<a href="#" class="nav-link">
							<i class="nav-icon fas fa-cogs orange"></i>
							<p>
								Configuration
								<i class="right fas fa-angle-left"></i>
							</p>
						</a>
						<ul class="nav nav-treeview">
							<li class="nav-item" v-can="'organizations'">
								<router-link to="/organizations" class="nav-link">
									<i class="far fa-circle nav-icon"></i>
									<p>Organization</p>
								</router-link>
							</li>
							<li class="nav-item" v-can="'roles'">
								<router-link to="/roles" class="nav-link">
									<i class="far fa-circle nav-icon"></i>
									<p>Roles</p>
								</router-link>
							</li>
							<li class="nav-item" v-can="'permissions.view'">
								<router-link to="/permissions" class="nav-link">
									<i class="far fa-circle nav-icon"></i>
									<p>Permissions</p>
								</router-link>
							</li>
							<li class="nav-item" v-can="'sliders'">
								<router-link to="/sliders" class="nav-link">
									<i class="far fa-circle nav-icon"></i>
									<p>Sliders</p>
								</router-link>
							</li>
							<li class="nav-item" v-can="'branches'">
								<router-link to="/branches" class="nav-link">
									<i class="far fa-circle nav-icon"></i>
									<p>Branches</p>
								</router-link>
							</li>
							<li class="nav-item" v-can="'denominations'">
								<router-link to="/denominations" class="nav-link">
									<i class="far fa-circle nav-icon"></i>
									<p>Denominations</p>
								</router-link>
							</li>
							<li class="nav-item" v-can="'stacks'">
								<router-link to="/stacks" class="nav-link">
									<i class="far fa-circle nav-icon"></i>
									<p>Stacks</p>
								</router-link>
							</li>
							<li class="nav-item" v-can="'groups'">
								<router-link to="/groups" class="nav-link">
									<i class="far fa-circle nav-icon"></i>
									<p>Groups</p>
								</router-link>
							</li>
							<li class="nav-item" v-can="'tables'">
								<router-link to="/tables" class="nav-link">
									<i class="far fa-circle nav-icon"></i>
									<p>Tables</p>
								</router-link>
							</li>
							<li class="nav-item" v-can="'terms&conditions'">
								<router-link to="/terms" class="nav-link">
									<i class="far fa-circle nav-icon"></i>
									<p>Terms & Conditions</p>
								</router-link>
							</li>
							<li class="nav-item" v-can="'pages'">
								<router-link to="/pages" class="nav-link">
									<i class="far fa-circle nav-icon"></i>
									<p>Pages</p>
								</router-link>
							</li>
							<li class="nav-item" v-can="'reference_types'">
								<router-link to="/reference_types" class="nav-link">
									<i class="far fa-circle nav-icon"></i>
									<p>Reference Types</p>
								</router-link>
							</li>
							<li class="nav-item" v-can="'payment_modes'">
								<router-link to="/payment_modes" class="nav-link">
									<i class="far fa-circle nav-icon"></i>
									<p>Payment Modes</p>
								</router-link>
							</li>
							<li class="nav-item" v-can="'payment_terms'">
								<router-link to="/payment_terms" class="nav-link">
									<i class="far fa-circle nav-icon"></i>
									<p>Payment Terms</p>
								</router-link>
							</li>
							<li class="nav-item" v-can="'categories'">
								<router-link to="/categories" class="nav-link">
									<i class="far fa-circle nav-icon"></i>
									<p>Categories</p>
								</router-link>
							</li>
							<li class="nav-item" v-can="'accounts'">
								<router-link to="/accounts" class="nav-link">
									<i class="far fa-circle nav-icon"></i>
									<p>Accounts</p>
								</router-link>
							</li>
							<li class="nav-item" v-can="'promotions'">
								<router-link to="/promotions" class="nav-link">
									<i class="far fa-circle nav-icon"></i>
									<p>Promotions</p>
								</router-link>
							</li>
							<li class="nav-item" v-can="'videos'">
								<router-link to="/videos" class="nav-link">
									<i class="far fa-circle nav-icon"></i>
									<p>Videos</p>
								</router-link>
							</li>
						</ul>
					</li>
					<li class="nav-item" v-can="'users.view'">
						<router-link to="/users" class="nav-link">
							<i class="nav-icon fas fa-user purple"></i>
							<p>Users</p>
						</router-link>
					</li>
					<li class="nav-item" v-if="permission('crm')">
						<a href="#" class="nav-link">
							<i class="nav-icon fas fa-users pink"></i>
							<p>
								CRM
								<i class="right fas fa-angle-left"></i>
							</p>
						</a>
						<ul class="nav nav-treeview">
							<li class="nav-item" v-can="'customers.view'">
								<router-link to="/customers" class="nav-link">
									<i class="far fa-circle nav-icon"></i>
									<p>Customers</p>
								</router-link>
							</li>
							<li class="nav-item" v-can="'databases.view'">
								<router-link to="/database" class="nav-link">
									<i class="far fa-circle nav-icon"></i>
									<p>Database</p>
								</router-link>
							</li>
							<li class="nav-item" v-can="'notifications.view'">
								<router-link to="/notifications" class="nav-link">
									<i class="far fa-circle nav-icon"></i>
									<p>Notifications</p>
								</router-link>
							</li>
							<li class="nav-item" v-can="'messages.view'">
								<router-link to="/messages" class="nav-link">
									<i class="far fa-circle nav-icon"></i>
									<p>Messages</p>
								</router-link>
							</li>
							<li class="nav-item" v-can="'whatsapp.view'">
								<router-link to="/whatsapp" class="nav-link">
									<i class="far fa-circle nav-icon"></i>
									<p>Whatsapp</p>
								</router-link>
							</li>
						</ul>
					</li>
					<li class="nav-item" v-if="permission('checkin_logs')">
						<a href="#" class="nav-link">
							<i class="nav-icon fas fa-clock red"></i>
							<p>
								CheckIn Logs
								<i class="right fas fa-angle-left"></i>
							</p>
						</a>
						<ul class="nav nav-treeview">
							<li class="nav-item" v-can="'customer_checkin_logs'">
								<router-link to="/logs" class="nav-link">
									<i class="far fa-circle nav-icon"></i>
									<p>Customer CheckIn Logs</p>
								</router-link>
							</li>
							<li class="nav-item" v-can="'employee_checkin_logs'">
								<router-link to="/logs/employees" class="nav-link">
									<i class="far fa-circle nav-icon"></i>
									<p>Employee CheckIn Logs</p>
								</router-link>
							</li>
						</ul>
					</li>
					<li class="nav-item" v-can="'sessions.view'">
						<router-link to="/sessions" class="nav-link">
							<i class="nav-icon fas fa-hourglass purple"></i>
							<p>Sessions</p>
						</router-link>
					</li>
					<li class="nav-item" v-can="'transactions.view'">
						<router-link to="/transactions" class="nav-link">
							<i class="nav-icon fas fa-credit-card yellow"></i>
							<p>Transactions</p>
						</router-link>
					</li>
					<li class="nav-item" v-if="permission('accounts')">
						<a href="#" class="nav-link">
							<i class="nav-icon fas fa-calculator blue"></i>
							<p>
								Accounts
								<i class="right fas fa-angle-left"></i>
							</p>
						</a>
						<ul class="nav nav-treeview">
							<li class="nav-item" v-can="'vendors.view'">
								<router-link to="/vendors" class="nav-link">
									<i class="far fa-circle nav-icon"></i>
									<p>Vendors</p>
								</router-link>
							</li>
							<li class="nav-item" v-can="'expenses.view'">
								<router-link to="/expenses" class="nav-link">
									<i class="far fa-circle nav-icon"></i>
									<p>Expenses</p>
								</router-link>
							</li>
						</ul>
					</li>
					<li class="nav-item" v-if="permission('reports')">
						<a href="#" class="nav-link">
							<i class="nav-icon fas fa-file-pdf white"></i>
							<p>
								Reports
								<i class="right fas fa-angle-left"></i>
							</p>
						</a>
						<ul class="nav nav-treeview">
							<li class="nav-item" v-can="'footfall_reports'">
								<router-link to="/customers/attendance" class="nav-link">
									<i class="far fa-circle nav-icon"></i>
									<p>Footfall Report</p>
								</router-link>
							</li>
							<li class="nav-item" v-can="'tips_reports'">
								<router-link to="/tips" class="nav-link">
									<i class="far fa-circle nav-icon"></i>
									<p>Tips Report</p>
								</router-link>
							</li>
							<li class="nav-item" v-can="'wallet_balance_reports'">
								<router-link to="/customer/wallet" class="nav-link">
									<i class="far fa-circle nav-icon"></i>
									<p>Wallet Balance Report</p>
								</router-link>
							</li>
							<li class="nav-item" v-can="'credit&obr_reports'">
								<router-link to="/credits" class="nav-link">
									<i class="far fa-circle nav-icon"></i>
									<p>Credit & OBR Report</p>
								</router-link>
							</li>
							<li class="nav-item" v-can="'experience_fees_reports'">
								<router-link to="/session/experience" class="nav-link">
									<i class="far fa-circle nav-icon"></i>
									<p>Experience Fees Report</p>
								</router-link>
							</li>
							<li class="nav-item" v-can="'monthly_reports'">
								<router-link to="/session/monthly" class="nav-link">
									<i class="far fa-circle nav-icon"></i>
									<p>Monthly Report</p>
								</router-link>
							</li>
							<li class="nav-item" v-can="'productivity_reports'">
								<router-link to="/session/productivity" class="nav-link">
									<i class="far fa-circle nav-icon"></i>
									<p>Productivity Report</p>
								</router-link>
							</li>
							<li class="nav-item" v-can="'playtime_reports'">
								<router-link to="/session/playtime" class="nav-link">
									<i class="far fa-circle nav-icon"></i>
									<p>Playtime Report</p>
								</router-link>
							</li>
						</ul>
					</li>

					<li class="nav-item">
						<a href="#" class="nav-link">
							<i class="nav-icon fas fa-mug-hot yellow"></i>
							<p>
								Food & Bevarages
								<i class="right fas fa-angle-left"></i>
							</p>
						</a>
						<ul class="nav nav-treeview">
							<li class="nav-item">
								<router-link to="/kitchens" class="nav-link">
									<i class="far fa-circle nav-icon"></i>
									<p>Kitchens</p>
								</router-link>
							</li>
							<li class="nav-item">
								<router-link to="/taxs" class="nav-link">
									<i class="far fa-circle nav-icon"></i>
									<p>Tax</p>
								</router-link>
							</li>
							<li class="nav-item">
								<router-link to="/item_categories" class="nav-link">
									<i class="far fa-circle nav-icon"></i>
									<p>Item Category</p>
								</router-link>
							</li>
							<li class="nav-item">
								<router-link to="/items" class="nav-link">
									<i class="far fa-circle nav-icon"></i>
									<p>Items</p>
								</router-link>
							</li>
						</ul>
					</li>

					<!-- <li class="nav-item" v-can="'logs.view'">
                        <router-link to="/api_logs" class="nav-link">
                            <i class="nav-icon fas fa-file teal"></i>
                            <p>Logs</p>
                        </router-link>
                    </li> -->
					<li class="nav-item">
						<a href="#" class="nav-link" @click.prevent="logout()">
							<i class="nav-icon fas fa-lock red"></i>
							<p>Logout</p>
						</a>
					</li>
				</ul>
			</nav>
		</div>
	</aside>
</template>

<script>
export default {
	name: "Menu",
	data() {
		return {
			permissions: this.$store.getters.permissions,
		};
	},
	methods: {
		logout() {
			if (confirm("Do you want to continue... ?")) {
				let vm = this;
				let loader = vm.$loading.show();
				vm.$store
					.dispatch("post", { uri: "logout", data: vm.$store.getters.user })
					.then(function () {
						loader.hide();
						vm.$store.dispatch("logout");
						vm.$router.push("/login");
						location.reload();
					})
					.catch(function (error) {
						loader.hide();
						vm.errors = error.response.data.errors;
						vm.$store.dispatch("error", error.response.data.message);
					});
			}
		},
		permission(ability) {
			let permissions = this.$store.getters.permissions;
			if (permissions && permissions.length != 0) {
				let permission = [];
				if (ability == "configuration") {
					permission = permissions.filter(function (el) {
						return (
							el.ability.ability == "roles" ||
							el.ability.ability == "organizations" ||
							el.ability.ability == "permissions.view" ||
							el.ability.ability == "sliders" ||
							el.ability.ability == "branches" ||
							el.ability.ability == "denominations" ||
							el.ability.ability == "stacks" ||
							el.ability.ability == "groups" ||
							el.ability.ability == "tables" ||
							el.ability.ability == "terms&conditions" ||
							el.ability.ability == "pages" ||
							el.ability.ability == "reference_types" ||
							el.ability.ability == "payment_modes" ||
							el.ability.ability == "payment_terms" ||
							el.ability.ability == "categories" ||
							el.ability.ability == "accounts" ||
							el.ability.ability == "promotions" ||
							el.ability.ability == "videos"
						);
					});
				}
				if (ability == "crm") {
					permission = permissions.filter(function (el) {
						return (
							el.ability.ability == "customers.view" ||
							el.ability.ability == "databases.view" ||
							el.ability.ability == "messages.view" ||
							el.ability.ability == "whatsapp.view" ||
							el.ability.ability == "notifications.view"
						);
					});
				}
				if (ability == "checkin_logs") {
					permission = permissions.filter(function (el) {
						return (
							el.ability.ability == "customer_checkin_logs" ||
							el.ability.ability == "employee_checkin_logs"
						);
					});
				}
				if (ability == "accounts") {
					permission = permissions.filter(function (el) {
						return (
							el.ability.ability == "vendors.view" ||
							el.ability.ability == "expenses.view"
						);
					});
				}
				if (ability == "reports") {
					permission = permissions.filter(function (el) {
						return (
							el.ability.ability == "wallet_balance_reports" ||
							el.ability.ability == "tips_reports" ||
							el.ability.ability == "productivity_reports" ||
							el.ability.ability == "playtime_reports" ||
							el.ability.ability == "monthly_reports" ||
							el.ability.ability == "footfall_reports" ||
							el.ability.ability == "experience_fees_reports" ||
							el.ability.ability == "credit&obr_reports"
						);
					});
				}
				if (permission.length == 0) {
					return false;
				} else {
					return true;
				}
			}
			return false;
		},
	},
};
</script>

<style scoped>
.hh:hover {
	color: white !important;
}
</style>
